import React from "react";

import ImageGallery from "react-image-gallery";

function PreviewImage(props: {
  items: GalleryPhoto[];
  show: string | undefined;
  onClose: () => void;
}) {
  const [selectedItem, setSelectedItem] = React.useState<
    undefined | GalleryPhoto
  >(undefined);
  const [itemsCursor, setItemCursor] = React.useState(0);
  const [virtualItems, setVirtualItems] = React.useState<GalleryPhoto[]>([]);
  const [virtualStartIndex, setVirtualStartIndex] = React.useState(0);
  const gallery = React.useRef(null);
  const itemsLimit = 20;

  const sliceItems = (items: GalleryPhoto[], startIndex: number) => {
    if (items.length <= itemsLimit) {
      setVirtualItems(items);
      setVirtualStartIndex(startIndex);
      return;
    }
    if (items.length === 0) {
      setVirtualItems(items);
      setVirtualStartIndex(startIndex);
      return;
    }

    const virtualItems: GalleryPhoto[] = [];
    let virtualStartIndex = 1;

    if (startIndex === 0) {
      virtualItems.push(items[items.length - 1]);
    } else {
      virtualItems.push(items[startIndex - 1]);
    }

    for (
      let index = startIndex;
      index < startIndex + (itemsLimit - 1);
      index++
    ) {
      if (index < items.length) {
        virtualItems.push(items[index]);
      } else {
        virtualItems.push(items[index % items.length]);
      }
    }

    setVirtualItems(virtualItems);
    setVirtualStartIndex(virtualStartIndex);
  };

  React.useEffect(() => {
    let startIndex = 0;
    props.items.forEach((item, i) => {
      if (item.image === props.show) startIndex = i;
    });
    setItemCursor(startIndex);
    setSelectedItem(props.items[startIndex]);
    sliceItems(props.items, startIndex);
  }, [props.items, props.items.length, props.show]);

  const onEscPressed = (event: KeyboardEvent) => {
    if (event.key === "Escape") props.onClose();
  };
  React.useEffect(() => {
    document.addEventListener("keyup", onEscPressed, false);
    return () => {
      document.removeEventListener("keyup", onEscPressed, false);
    };
  }, []);

  if (!selectedItem) return <></>;

  return (
    <div
      className="bg-black fixed top-0 left-0 right-0 bottom-0"
      style={{ zIndex: 9999999999 }}
    >
      <ImageGallery
        key={`gallery-${itemsCursor}`}
        ref={gallery}
        startIndex={virtualStartIndex}
        items={virtualItems.map((item) => {
          return {
            original: item.image,
            thumbnail: item.image,
            originalTitle: item.title,
            description: item.description,
            thumbnailClass: "preview-thumbnail",
          };
        })}
        onBeforeSlide={(index) => {
          setSelectedItem(virtualItems[index]);
        }}
        onSlide={(index) => {
          if (props.items.length <= itemsLimit) {
            return;
          }
          if (props.items.length === 0) {
            return;
          }

          if (index === 0) {
            let newStartIndex = itemsCursor - 1;
            if (newStartIndex < 0) newStartIndex = props.items.length - 1;
            setItemCursor(newStartIndex);
            sliceItems(props.items, newStartIndex);
          }
          if (index >= virtualItems.length - 1) {
            let newStartIndex = itemsCursor + (index - 1);
            if (newStartIndex >= props.items.length - 1)
              newStartIndex = newStartIndex % props.items.length;
            setItemCursor(newStartIndex);
            sliceItems(props.items, newStartIndex);
          }
        }}
        renderCustomControls={() => {
          return (
            <>
              <button
                className="absolute z-10 top-3 right-3 p-1 text-white bg-black/25 rounded-full"
                onClick={props.onClose}
              >
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <a
                className="absolute z-10 top-3 right-14 p-1 text-white bg-black/25 rounded-full"
                href={selectedItem.image}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <button
                className="absolute z-10 top-3 left-3 p-1 text-white bg-black/25 rounded-full"
                onClick={() => {}}
              >
                <div className="flex items-center">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="ml-1 mr-2">{selectedItem.title}</span>
                </div>
              </button>
            </>
          );
        }}
      />
    </div>
  );
}

export default PreviewImage;
