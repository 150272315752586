import React, { useState, useEffect, useMemo } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import formatNumber from "format-number";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Grid,
  Paper,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
  IconButton,
} from "@material-ui/core";

import { TransitionProps } from "@material-ui/core/transitions";

import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import { BaseAPI } from "../../data/BaseAPI";
import { useProfile } from "../../data/Profile";
import { Customer, User } from "../../types";

import localization from "../../utils/localizations";
import CustomerForm from "../forms/CustomerForm";
import WarrantyClaimForm from "../forms/WarrantyClaimForm";
import WarrantyTransferRequestForm from "../forms/WarrantyTransferRequestForm";
import moment from "moment";
import WarrantyClaimCollection from "../collections/WarrantyClaimCollection";
import ClaimAttachments from "../collections/ClaimAttachments";
import CustomerComments from "../collections/CustomerComments";

import PreviewImage from "./PreviewImage";
import PDFPreviewDialog from "./PDFPreviewDialog";

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
  partNumber: {
    marginRight: 10,
    minWidth: 100,
  },
  alignRight: {
    textAlign: "right",
  },
  bolder: {
    fontWeight: "bold",
  },
  noteContainer: {
    marginBottom: 20,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  },
  appBar: {
    position: "relative",
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function isImage(filename: string) {
  if (!filename.match(/\.(jpg|jpeg|png|gif)$/i)) return false;
  return true;
}

function isPdf(filename: string) {
  if (!filename.match(/\.(pdf)$/i)) return false;
  return true;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomerDetail(props: {
  customer: Customer;
  onUpdated?: (customer: Customer) => void;
  onDeleted?: (customer: Customer) => void;
  className?: string;
}) {
  const classes = useStyles();
  const [showEditForm, setShowEditForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [showCreateClaimForm, setShowCreateClaimForm] = useState(false);
  const [showEditWarrantyTransferForm, setShowEditWarrantyTransferForm] =
    useState(false);
  const [redirect, setRedirect] = useState("");

  const [showPreviewImage, setShowPreviewImage] = useState<undefined | string>(
    undefined,
  );
  const [showPdf, setShowPdf] = useState<undefined | GalleryPhoto>(undefined);

  const previewImages = useMemo(() => {
    let gallery: GalleryPhoto[] = [];
    props.customer.attachments
      .filter((attachment) => isImage(attachment.name))
      .map((attachment, i) => {
        gallery.push({
          description: `Attachment #${i + 1}`,
          title: attachment.name,
          image: `${attachment.url}download/`,
        });
      });

    return gallery;
  }, [props.customer]);

  const customer = props.customer;

  const [profile, profileLoading, updateProfile, updateProfilePicture] =
    useProfile();

  const deleteCustomer = async (customer: Customer) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.delete(
        `warranty-customers/${customer.id}/`,
      );
      if (props.onDeleted) props.onDeleted(customer);
    } catch (error) {
      console.error(error);
    }
  };
  let isAdmin = false;
  if (profile && profile.role === "admin") {
    isAdmin = true;
  }

  let canEdit = false;
  if (profile && profile.role === "admin") {
    canEdit = true;
  }
  let canDelete = canEdit;

  let canCreateClaim = true;

  if (profile && profile.role === "customer" && customer.stock) {
    profile.dealers.forEach((dealer) => {
      if (dealer.id === customer.dealer?.id) {
        canEdit = true;
      }
    });
  }

  if (canEdit && showEditForm) {
    return (
      <div className={`${classes.root} ${props.className}`}>
        <CustomerForm
          customer={props.customer}
          dealerId=""
          onSave={(customer) => {
            setShowEditForm(false);
            if (props.onUpdated) props.onUpdated(customer);
          }}
          onCancel={() => {
            setShowEditForm(false);
          }}
        />
      </div>
    );
  }

  if (!props.onDeleted) canDelete = false;
  if (!props.onUpdated) canEdit = false;

  let serialNumber = props.customer?.boat?.serial_number;
  if (serialNumber && serialNumber.startsWith("US")) {
    serialNumber = serialNumber.substring(2);
  }

  return (
    <div className={`${classes.root} ${props.className}`}>
      <Grid
        container
        spacing={3}
        component={Paper}
        style={{ margin: 0, marginBottom: 12, width: "100%" }}
      >
        <Grid item xs={4}>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="h6">Customer Information</Typography>
            <Typography variant="body2">
              Name: {props.customer.first_name} {props.customer.last_name}{" "}
              <br />
              Email:{" "}
              {props.customer.email ? (
                <a href={`mailto:${props.customer.email}`}>
                  {props.customer.email}
                </a>
              ) : (
                "-"
              )}{" "}
              <br />
              Phone:{" "}
              {props.customer.phone ? (
                <a href={`tel:${props.customer.phone}`}>
                  {props.customer.phone}
                </a>
              ) : (
                "-"
              )}{" "}
              <br />
              {props.customer.first_owner ? (
                <span>
                  First Owner <br />
                </span>
              ) : (
                ""
              )}
              {props.customer.second_owner ? (
                <span>
                  Second Owner <br />
                </span>
              ) : (
                ""
              )}
              {props.customer.servicing_dealer ? (
                <span>
                  Servicing Dealer <br />
                </span>
              ) : (
                ""
              )}
              {props.customer.no_warranty ? (
                <span>
                  No Warranty / See Notes <br />
                </span>
              ) : (
                ""
              )}
              {props.customer.stock ? (
                <Chip label="Stock" className="mr-2" />
              ) : (
                ""
              )}
              {props.customer.rental_fleet ? <Chip label="Rental Fleet" /> : ""}
            </Typography>
          </div>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="body1">Address</Typography>
            <Typography variant="body2">
              {props.customer.address_line_1}
            </Typography>
            <Typography variant="body2">
              {props.customer.city} {props.customer.state} {props.customer.zip}
            </Typography>
            <Typography variant="body2">{props.customer.country}</Typography>
          </div>
          {profile.role === "admin" && (
            <div className={classes.shippingAddressContainer}>
              {!!props.customer.created_by && (
                <Typography variant="body2">
                  Created By: {props.customer.created_by.first_name}{" "}
                  {props.customer.created_by.last_name} on{" "}
                  {moment(props.customer.created_at).format(
                    "MMMM Do YYYY hh:mm:ss",
                  )}
                </Typography>
              )}
              {!!props.customer.updated_by && (
                <Typography variant="body2">
                  Updated By: {props.customer.updated_by.first_name}{" "}
                  {props.customer.updated_by.last_name} on{" "}
                  {moment(props.customer.updated_at).format(
                    "MMMM Do YYYY hh:mm:ss",
                  )}
                </Typography>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={4}>
          {!!props.customer.boat && (
            <div className={classes.noteContainer}>
              <Typography variant="h6">Boat</Typography>
              <Typography variant="body2">
                Model: {props.customer.boat.model} <br />
                Serial / HIN: {props.customer.boat.serial_number} <br />
                Engine #1 Serial: {props.customer.boat.engine_serial} <br />
                {props.customer.boat.engine_serial_2 && (
                  <div>
                    Engine #2 Serial: {props.customer.boat.engine_serial_2}
                  </div>
                )}
                {props.customer.boat.engine_serial_3 && (
                  <div>
                    Engine #3 Serial: {props.customer.boat.engine_serial_3}
                  </div>
                )}
                Purchase Date:{" "}
                {moment(props.customer.boat.purchase_date).format(
                  "MMMM Do YYYY",
                )}{" "}
                <br />
                Registration Date:{" "}
                {moment(props.customer.boat.registration_date).format(
                  "MMMM Do YYYY",
                )}{" "}
                <br />
              </Typography>
            </div>
          )}
        </Grid>

        {!!props.customer.dealer &&
          props.customer.dealer.customer_number != "0" && (
            <Grid item xs={4}>
              <div className={classes.shippingAddressContainer}>
                <Typography variant="h6">Dealer</Typography>
                <Typography variant="body2">
                  Dealer Name: {props.customer.dealer.customer_name} <br />
                  Dealer #:{" "}
                  {props.customer.dealer.customer_number.padStart(5, "0")}{" "}
                  <br />
                  Email:{" "}
                  {props.customer.dealer.email ? (
                    <a href={`mailto:${props.customer.dealer.email}`}>
                      {props.customer.dealer.email}
                    </a>
                  ) : (
                    "-"
                  )}{" "}
                  <br />
                </Typography>
              </div>
              {!props.customer.dealer_address && (
                <>
                  {props.customer.dealer.addresses.map((dealerAddress, i) => (
                    <div
                      className={classes.shippingAddressContainer}
                      key={`address-${i}`}
                    >
                      <Typography variant="body1">Address #{i + 1}</Typography>
                      <Typography variant="body2">
                        Email:{" "}
                        {dealerAddress.email ? (
                          <a href={`mailto:${dealerAddress.email}`}>
                            {dealerAddress.email}
                          </a>
                        ) : (
                          "-"
                        )}{" "}
                        <br />
                        Phone:{" "}
                        {dealerAddress.phone ? (
                          <a href={`tel:${dealerAddress.phone}`}>
                            {dealerAddress.phone}
                          </a>
                        ) : (
                          "-"
                        )}{" "}
                        <br />
                      </Typography>
                      <Typography variant="body2">
                        {dealerAddress.address_line_1}
                      </Typography>
                      <Typography variant="body2">
                        {dealerAddress.city} {dealerAddress.state}{" "}
                        {dealerAddress.zip}
                      </Typography>
                      <Typography variant="body2">
                        {dealerAddress.country}
                      </Typography>
                    </div>
                  ))}
                </>
              )}
              {!!props.customer.dealer_address && (
                <div className={classes.shippingAddressContainer}>
                  <Typography variant="body1">Address</Typography>
                  <Typography variant="body2">
                    Email:{" "}
                    {props.customer.dealer_email ? (
                      <a href={`mailto:${props.customer.dealer_email}`}>
                        {props.customer.dealer_email}
                      </a>
                    ) : (
                      "-"
                    )}{" "}
                    <br />
                    Phone:{" "}
                    {props.customer.dealer_phone ? (
                      <a href={`tel:${props.customer.dealer_phone}`}>
                        {props.customer.dealer_phone}
                      </a>
                    ) : (
                      "-"
                    )}{" "}
                    <br />
                  </Typography>
                  <Typography variant="body2">
                    {props.customer.dealer_address}
                  </Typography>
                  <Typography variant="body2">
                    {props.customer.dealer_city} {props.customer.dealer_state}{" "}
                    {props.customer.dealer_zip}
                  </Typography>
                  <Typography variant="body2">
                    {props.customer.dealer_country}
                  </Typography>
                </div>
              )}
            </Grid>
          )}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={8}>
          <CustomerComments
            readOnly={!isAdmin}
            customerId={props.customer.id}
          />
        </Grid>
        <Grid item xs={4}>
          {props.customer.attachments.length > 0 && (
            <ClaimAttachments
              title="Attachments"
              attachments={props.customer.attachments}
              readOnly
              onPreview={(attachment) => {
                if (isImage(attachment.name)) {
                  setShowPreviewImage(`${attachment.url}download/`);
                  setShowPdf(undefined);
                } else if (isPdf(attachment.name)) {
                  setShowPdf({
                    description: ``,
                    title: attachment.name,
                    image: `${attachment.url}download/`,
                  });
                  setShowPreviewImage(undefined);
                }
              }}
            />
          )}
        </Grid>
      </Grid>

      <div
        style={{
          marginBottom: 20,
          marginTop: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {canEdit && (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowEditForm(true)}
              style={{ marginRight: 10 }}
            >
              Edit
            </Button>

            {canDelete && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (window.confirm("Remove this customer?")) {
                    deleteCustomer(props.customer);
                  }
                }}
                style={{ marginRight: 10 }}
              >
                Delete
              </Button>
            )}
          </div>
        )}
        <div>
          <Button
            variant="contained"
            target="_blank"
            href={`/api/v1/warranty-customers-download/?id=${props.customer.id}`}
            startIcon={<CloudDownloadIcon />}
            style={{ marginRight: 10 }}
          >
            Download CSV
          </Button>

          <Button
            color="secondary"
            variant="outlined"
            style={{ marginRight: 10 }}
            onClick={() => setShowEditWarrantyTransferForm(true)}
          >
            Request Warranty Transfer
          </Button>
        </div>
      </div>

      <hr />

      {!!serialNumber && isAdmin && (
        <WarrantyClaimCollection
          serialNumber={serialNumber}
          dealer={props.customer.dealer}
          hideFilter
          showAll
        />
      )}

      {canCreateClaim && (
        <div style={{ marginTop: 24, marginBottom: 24 }}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            onClick={() => {
              setShowCreateClaimForm(true);
            }}
          >
            Create a New Claim
          </Button>
        </div>
      )}

      {!!redirect && <Redirect to={redirect} />}

      <Dialog
        open={showCreateClaimForm}
        onClose={() => {
          setShowCreateClaimForm(false);
        }}
        fullScreen
        TransitionComponent={Transition}
      >
        <>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.dialogTitle}>
                Create New Warranty Claim
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setShowCreateClaimForm(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <WarrantyClaimForm
              claim={null}
              dealerId=""
              customer={customer}
              onSave={(claim) => {
                setShowCreateClaimForm(false);
                setRedirect(`/warranty/claims/${claim.id}/`);
              }}
              onCancel={() => {
                setShowCreateClaimForm(false);
              }}
            />
          </DialogContent>
        </>
      </Dialog>

      <Dialog
        open={showEditWarrantyTransferForm}
        onClose={() => {
          setShowEditWarrantyTransferForm(false);
        }}
        fullScreen
        TransitionComponent={Transition}
      >
        <>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.dialogTitle}>
                Create New Warranty Transfer Request
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setShowEditWarrantyTransferForm(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <WarrantyTransferRequestForm
              transfer={undefined}
              customerId={customer.id}
              serialNumber={customer.boat.serial_number}
              dealerId=""
              onSave={(transfer) => {
                setShowEditWarrantyTransferForm(false);
                setRedirect(`/warranty/warranty-transfers/${transfer.id}/`);
              }}
              onCancel={() => {
                setShowEditWarrantyTransferForm(false);
              }}
            />
          </DialogContent>
        </>
      </Dialog>

      {!!showPreviewImage && (
        <PreviewImage
          items={previewImages}
          show={showPreviewImage}
          onClose={() => {
            setShowPreviewImage(undefined);
          }}
        />
      )}
      <PDFPreviewDialog
        url={showPdf?.image}
        title={showPdf?.title}
        description={showPdf?.description}
        onClose={() => setShowPdf(undefined)}
      />
    </div>
  );
}
