import React, { useState, useEffect, useRef } from "react";

import moment from "moment";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import formatNumber from "format-number";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardActions,
  CardContent,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DownloadIcon from "@material-ui/icons/GetApp";

import ClaimAttachments from "../collections/ClaimAttachments";

import { APIError, BaseAPI } from "../../data/BaseAPI";

import { useProfile } from "../../data/Profile";

import {
  Customer,
  WarrantyClaim,
  User,
  Session,
  Boat,
  ClaimLaborItem,
  ClaimPartItem,
  ClaimSubletItem,
  LaborCategory,
  JobDescription,
  ClaimAttachment,
} from "../../types";

import localization from "../../utils/localizations";

import CountrySelect from "./fields/CountrySelect";
import StateSelect from "./fields/StateSelect";

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles({
  root: {
    margin: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: "right",
  },
  closeButton: {
    padding: 4,
  },
  downloadButton: {
    marginRight: 6,
  },
  lineItem: {
    minWidth: "90%",
    marginBottom: 12,
  },
  lineItemTitle: {
    fontSize: 14,
  },
});

interface LaborFormValues {
  labor_category: string;
  job_description: string;
  labor_hours: string;
  comments: string;
}

function LaborForm(props: {
  labor: ClaimLaborItem | null | undefined;
  onSave: (labor: ClaimLaborItem) => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [laborCategories, setLaborCategories] = useState(
    ((window as any)._cached && (window as any)._cached["labor_categories"]
      ? (window as any)._cached["labor_categories"]
      : []) as LaborCategory[],
  );
  const [jobDescriptions, setJobDescriptions] = useState(
    ((window as any)._cached && (window as any)._cached["job_descriptions"]
      ? (window as any)._cached["job_descriptions"]
      : []) as JobDescription[],
  );

  const [attachments, setAttachments] = useState<ClaimAttachment[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);

  useEffect(() => {
    if (props.labor) {
      setAttachments(props.labor.attachments);
      if ((props.labor as any).removed_attachment_ids) {
        setRemovedAttachments(
          (props.labor as any).removed_attachment_ids as string[],
        );
      } else {
        setRemovedAttachments([]);
      }
    }
  }, [props.labor]);

  const loadLaborCategories = async () => {
    const api = new BaseAPI();
    let url = `warranty-labor-categories/`;
    let kwargs: any = {};
    url = `${url}?${new URLSearchParams(kwargs).toString()}`;
    try {
      const data: any = await api.get(url);
      if (data instanceof Array) {
        setLaborCategories(data as LaborCategory[]);
        (window as any)._cached["labor_categories"] = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadJobDescriptions = async () => {
    const api = new BaseAPI();
    let url = `warranty-job-descriptions/`;
    let kwargs: any = {};
    url = `${url}?${new URLSearchParams(kwargs).toString()}`;
    try {
      const data: any = await api.get(url);
      if (data instanceof Array) {
        setJobDescriptions(data as JobDescription[]);
        (window as any)._cached["job_descriptions"] = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const findLaborCategory = (id: string, d: LaborCategory) => {
    let category = d;
    laborCategories.forEach((c) => {
      if (c.id === id) category = c;
    });
    return category;
  };

  const findJobDescription = (id: string, d: JobDescription) => {
    let jd = d;
    jobDescriptions.forEach((j) => {
      if (j.id === id) jd = j;
    });
    return jd;
  };

  useEffect(() => {
    loadLaborCategories();
    loadJobDescriptions();
  }, []);

  const onSubmit = async (values: LaborFormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    if (props.labor) {
      const labor = Object.assign(props.labor, {
        labor_category: findLaborCategory(
          values.labor_category,
          props.labor.labor_category,
        ),
        job_description: findJobDescription(
          values.job_description,
          props.labor.job_description,
        ),
        labor_hours: values.labor_hours,
        comments: values.comments,
        attachments: Object.assign([], attachments),
        attachment_ids: attachments.map((attachment) => attachment.id),
        removed_attachment_ids: removedAttachments,
        updated_at: moment().format(),
      });
      console.log(labor);
      props.onSave(labor);
    }

    setIsSaving(false);
  };

  const onValidate = (values: LaborFormValues) => {
    const errors: any = {};

    if (!values.labor_category) errors.labor_category = "Required";
    if (!values.job_description) errors.job_description = "Required";
    if (!values.labor_hours) errors.labor_hours = "Required";

    return errors;
  };

  let labor = props.labor;
  return (
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        labor_category: labor ? labor.labor_category.id : "",
        job_description: labor ? labor.job_description?.id : "",
        labor_hours: labor ? labor.labor_hours : "",
        comments: labor ? labor.comments : "",
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <DialogTitle id="form-dialog-title">Labor</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid container spacing={3}>
                <Field name="labor_category">
                  {(props) => (
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.fieldContainer}
                        error={
                          props.meta.error && props.meta.touched
                            ? !!props.meta.error
                            : undefined
                        }
                      >
                        <InputLabel>Labor Category</InputLabel>
                        <Select
                          id={props.input.name}
                          label={"Labor Category"}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value}
                          onChange={props.input.onChange}
                          error={
                            (props.meta.error || props.meta.submitError) &&
                            props.meta.touched
                              ? true
                              : false
                          }
                        >
                          {laborCategories.map((laborCategory, i) => (
                            <MenuItem
                              key={`labor-category-menu-item-${i}`}
                              value={laborCategory.id}
                            >
                              {laborCategory.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {(props.meta.error || props.meta.submitError) &&
                          props.meta.touched && (
                            <Typography style={{ color: "red" }}>
                              {props.meta.error || props.meta.submitError}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                  )}
                </Field>
                <Field name="job_description">
                  {(props) => (
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.fieldContainer}
                        error={
                          props.meta.error && props.meta.touched
                            ? !!props.meta.error
                            : undefined
                        }
                      >
                        <InputLabel>Job Description</InputLabel>
                        <Select
                          id={props.input.name}
                          label={"Job Description"}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(event) => {
                            props.input.onChange(event);
                            jobDescriptions.forEach((jobDescription) => {
                              if (jobDescription.id === event.target.value) {
                                form.change(
                                  "labor_hours",
                                  jobDescription.labor_hours,
                                );
                              }
                            });
                          }}
                          error={
                            (props.meta.error || props.meta.submitError) &&
                            props.meta.touched
                              ? true
                              : false
                          }
                        >
                          {jobDescriptions
                            .filter(
                              (jobDescription) =>
                                jobDescription.category.id ===
                                values.labor_category,
                            )
                            .map((jobDescriptions, i) => (
                              <MenuItem
                                key={`job-description-menu-item-${i}`}
                                value={jobDescriptions.id}
                              >
                                {jobDescriptions.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {(props.meta.error || props.meta.submitError) &&
                          props.meta.touched && (
                            <Typography style={{ color: "red" }}>
                              {props.meta.error || props.meta.submitError}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>

              <Field name="labor_hours">
                {(props) => (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.fieldContainer}
                      error={
                        props.meta.error && props.meta.touched
                          ? !!props.meta.error
                          : undefined
                      }
                    >
                      <TextField
                        id={props.input.name}
                        label={"Labor Hours"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? true
                            : false
                        }
                        helperText={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? props.meta.error || props.meta.submitError
                            : undefined
                        }
                        type="number"
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="comments">
                {(props) => (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.fieldContainer}
                      error={
                        props.meta.error && props.meta.touched
                          ? !!props.meta.error
                          : undefined
                      }
                    >
                      <TextField
                        id={props.input.name}
                        label={"Comment"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? true
                            : false
                        }
                        helperText={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? props.meta.error || props.meta.submitError
                            : undefined
                        }
                        multiline
                        rows={4}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Grid item xs={12}>
                <ClaimAttachments
                  title="Attachments"
                  attachments={attachments}
                  onAdded={(attachment) => {
                    const newAttachments = Object.assign([], attachments);
                    newAttachments.push(attachment);
                    setAttachments(newAttachments);
                  }}
                  onRemoved={(attachment) => {
                    const index = attachments.findIndex(
                      (a) => a.id === attachment.id,
                    );
                    const newAttachments = Object.assign([], attachments);
                    if (index >= 0) {
                      newAttachments.splice(index, 1);
                    }
                    setAttachments(newAttachments);

                    const newRemovedAttachments = Object.assign(
                      [],
                      removedAttachments,
                    );
                    newRemovedAttachments.push(attachment.id);
                    setRemovedAttachments(newRemovedAttachments);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "space-between", padding: 24 }}
          >
            <Button
              type="submit"
              disabled={isSaving}
              color="primary"
              variant="contained"
            >
              {props.labor?.id ? "Update" : "Add"}
            </Button>
            <Button onClick={() => props.onCancel()} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      )}
    />
  );
}

interface PartFormValues {
  type: string;
  part_number: string;
  description: string;
  unit_cost: string;
  quantity: number;
  total_cost: string;
  comments: string;
}

function PartForm(props: {
  part: ClaimPartItem | null | undefined;
  onSave: (part: ClaimPartItem) => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);

  const [attachments, setAttachments] = useState<ClaimAttachment[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);

  useEffect(() => {
    if (props.part) {
      setAttachments(props.part.attachments);
      if ((props.part as any).removed_attachment_ids) {
        setRemovedAttachments(
          (props.part as any).removed_attachment_ids as string[],
        );
      } else {
        setRemovedAttachments([]);
      }
    }
  }, [props.part]);

  const onSubmit = async (values: PartFormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    if (props.part) {
      const part = Object.assign(props.part, {
        type: values.type,
        part_number: values.part_number,
        description: values.description,
        quantity: values.quantity,
        unit_cost: values.type === "credit" ? values.unit_cost : "0",
        total_cost: values.type === "credit" ? values.total_cost : "0",
        comments: values.comments,
        attachments: Object.assign([], attachments),
        attachment_ids: attachments.map((attachment) => attachment.id),
        removed_attachment_ids: removedAttachments,
        updated_at: moment().format(),
      });
      console.log(part);
      props.onSave(part);
    }

    setIsSaving(false);
  };

  const onValidate = (values: PartFormValues) => {
    const errors: any = {};

    if (!values.type) errors.type = "Required";
    if (!values.part_number) errors.part_number = "Required";
    if (!values.description) errors.description = "Required";
    if (!values.quantity) errors.quantity = "Required";
    if (values.type === "credit") {
      if (!values.total_cost) errors.total_cost = "Required";
      if (!values.unit_cost) errors.unit_cost = "Required";
    }

    return errors;
  };

  let part = props.part;
  return (
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        type: part ? part.type : "",
        part_number: part ? part.part_number : "",
        description: part ? part.description : "",
        unit_cost: part ? part.unit_cost : "",
        quantity: part ? part.quantity : 1,
        total_cost: part ? part.total_cost : "",
        comments: part ? part.comments : "",
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <DialogTitle id="form-dialog-title">Parts</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid container spacing={3}>
                <Field name="type">
                  {(props) => (
                    <Grid item xs={3}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.fieldContainer}
                        error={
                          props.meta.error && props.meta.touched
                            ? !!props.meta.error
                            : undefined
                        }
                      >
                        <InputLabel>Ship / Credit</InputLabel>
                        <Select
                          id={props.input.name}
                          label={"Ship / Credit"}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value}
                          onChange={props.input.onChange}
                          error={
                            (props.meta.error || props.meta.submitError) &&
                            props.meta.touched
                              ? true
                              : false
                          }
                        >
                          <MenuItem value="ship">Ship</MenuItem>
                          <MenuItem value="credit">Credit</MenuItem>
                        </Select>
                        {(props.meta.error || props.meta.submitError) &&
                          props.meta.touched && (
                            <Typography style={{ color: "red" }}>
                              {props.meta.error || props.meta.submitError}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                  )}
                </Field>
                <Field name="quantity">
                  {(props) => (
                    <Grid item xs={3}>
                      <FormControl
                        fullWidth
                        className={classes.fieldContainer}
                        error={
                          props.meta.error && props.meta.touched
                            ? !!props.meta.error
                            : undefined
                        }
                      >
                        <TextField
                          id={props.input.name}
                          label={"Quantity"}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(event) => {
                            props.input.onChange(event);
                            let unitCost = parseFloat(values.unit_cost);
                            if (isNaN(unitCost)) return;
                            let quantity = parseFloat(event.target.value);
                            if (isNaN(quantity)) return;
                            form.change(
                              "total_cost",
                              (unitCost * quantity).toFixed(2),
                            );
                          }}
                          error={
                            (props.meta.error || props.meta.submitError) &&
                            props.meta.touched
                              ? true
                              : false
                          }
                          helperText={
                            (props.meta.error || props.meta.submitError) &&
                            props.meta.touched
                              ? props.meta.error || props.meta.submitError
                              : undefined
                          }
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
                {values.type === "credit" && (
                  <Field name="unit_cost">
                    {(props) => (
                      <Grid item xs={3}>
                        <FormControl
                          fullWidth
                          className={classes.fieldContainer}
                          error={
                            props.meta.error && props.meta.touched
                              ? !!props.meta.error
                              : undefined
                          }
                        >
                          <TextField
                            id={props.input.name}
                            label={"Unit Cost"}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={(event) => {
                              props.input.onChange(event);
                              let unitCost = parseFloat(event.target.value);
                              if (isNaN(unitCost)) return;
                              let quantity = values.quantity;
                              if (isNaN(quantity)) return;
                              form.change(
                                "total_cost",
                                (unitCost * quantity).toFixed(2),
                              );
                            }}
                            error={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? true
                                : false
                            }
                            helperText={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? props.meta.error || props.meta.submitError
                                : undefined
                            }
                            type="number"
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                )}
                {values.type === "credit" && (
                  <Field name="total_cost">
                    {(props) => (
                      <Grid item xs={3}>
                        <FormControl
                          fullWidth
                          className={classes.fieldContainer}
                          error={
                            props.meta.error && props.meta.touched
                              ? !!props.meta.error
                              : undefined
                          }
                        >
                          <TextField
                            id={props.input.name}
                            label={"Total Cost"}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            error={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? true
                                : false
                            }
                            helperText={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? props.meta.error || props.meta.submitError
                                : undefined
                            }
                            type="number"
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                )}
              </Grid>

              <Field name="part_number">
                {(props) => (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.fieldContainer}
                      error={
                        props.meta.error && props.meta.touched
                          ? !!props.meta.error
                          : undefined
                      }
                    >
                      <TextField
                        id={props.input.name}
                        label={"Part Number"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? true
                            : false
                        }
                        helperText={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? props.meta.error || props.meta.submitError
                            : undefined
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="description">
                {(props) => (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.fieldContainer}
                      error={
                        props.meta.error && props.meta.touched
                          ? !!props.meta.error
                          : undefined
                      }
                    >
                      <TextField
                        id={props.input.name}
                        label={"Description"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? true
                            : false
                        }
                        helperText={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? props.meta.error || props.meta.submitError
                            : undefined
                        }
                        multiline
                        rows={4}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="comments">
                {(props) => (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.fieldContainer}
                      error={
                        props.meta.error && props.meta.touched
                          ? !!props.meta.error
                          : undefined
                      }
                    >
                      <TextField
                        id={props.input.name}
                        label={"Comments"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? true
                            : false
                        }
                        helperText={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? props.meta.error || props.meta.submitError
                            : undefined
                        }
                        multiline
                        rows={4}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Grid item xs={12}>
                <ClaimAttachments
                  title="Attachments"
                  attachments={attachments}
                  onAdded={(attachment) => {
                    const newAttachments = Object.assign([], attachments);
                    newAttachments.push(attachment);
                    setAttachments(newAttachments);
                  }}
                  onRemoved={(attachment) => {
                    const index = attachments.findIndex(
                      (a) => a.id === attachment.id,
                    );
                    const newAttachments = Object.assign([], attachments);
                    if (index >= 0) {
                      newAttachments.splice(index, 1);
                    }
                    setAttachments(newAttachments);

                    const newRemovedAttachments = Object.assign(
                      [],
                      removedAttachments,
                    );
                    newRemovedAttachments.push(attachment.id);
                    setRemovedAttachments(newRemovedAttachments);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "space-between", padding: 24 }}
          >
            <Button
              type="submit"
              disabled={isSaving}
              variant="contained"
              color="primary"
            >
              {props.part?.id ? "Update" : "Add"}
            </Button>
            <Button onClick={() => props.onCancel()} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      )}
    />
  );
}

interface SubletFormValues {
  company_name: string;
  invoice_number: string;
  cost: string;
  description: string;
  comments: string;
  attachments: ClaimAttachment[];
}

function SubletForm(props: {
  sublet: ClaimSubletItem | null | undefined;
  onSave: (sublet: ClaimSubletItem) => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);

  const [attachments, setAttachments] = useState<ClaimAttachment[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);

  useEffect(() => {
    if (props.sublet) {
      setAttachments(props.sublet.attachments);
      if ((props.sublet as any).removed_attachment_ids) {
        setRemovedAttachments(
          (props.sublet as any).removed_attachment_ids as string[],
        );
      } else {
        setRemovedAttachments([]);
      }
    }
  }, [props.sublet]);

  const onSubmit = async (values: SubletFormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    if (props.sublet) {
      const sublet = Object.assign(props.sublet, {
        company_name: values.company_name,
        invoice_number: values.invoice_number,
        cost: values.cost,
        description: values.description,
        comments: values.comments,
        attachments: Object.assign([], attachments),
        attachment_ids: attachments.map((attachment) => attachment.id),
        removed_attachment_ids: removedAttachments,
        updated_at: moment().format(),
      });
      console.log(sublet);
      props.onSave(sublet);
    }

    setIsSaving(false);
  };

  const onValidate = (values: SubletFormValues) => {
    const errors: any = {};

    if (attachments.length === 0)
      errors[FORM_ERROR] = "Please upload an attachment!";
    if (!values.company_name) errors.company_name = "Required";
    if (!values.invoice_number) errors.invoice_number = "Required";
    if (!values.cost) errors.cost = "Required";
    if (!values.description) errors.description = "Required";

    return errors;
  };

  let sublet = props.sublet;
  return (
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        company_name: sublet ? sublet.company_name : "",
        invoice_number: sublet ? sublet.invoice_number : "",
        cost: sublet ? sublet.cost : "",
        description: sublet ? sublet.description : "",
        comments: sublet ? sublet.comments : "",
      }}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <DialogTitle id="form-dialog-title">Parts</DialogTitle>
          <DialogContent>
            <Grid container>
              <Field name="description">
                {(props) => (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.fieldContainer}
                      error={
                        props.meta.error && props.meta.touched
                          ? !!props.meta.error
                          : undefined
                      }
                    >
                      <TextField
                        id={props.input.name}
                        label={"Description"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? true
                            : false
                        }
                        helperText={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? props.meta.error || props.meta.submitError
                            : undefined
                        }
                        multiline
                        rows={4}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="company_name">
                {(props) => (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.fieldContainer}
                      error={
                        props.meta.error && props.meta.touched
                          ? !!props.meta.error
                          : undefined
                      }
                    >
                      <TextField
                        id={props.input.name}
                        label={"Company Name"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? true
                            : false
                        }
                        helperText={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? props.meta.error || props.meta.submitError
                            : undefined
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="invoice_number">
                {(props) => (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.fieldContainer}
                      error={
                        props.meta.error && props.meta.touched
                          ? !!props.meta.error
                          : undefined
                      }
                    >
                      <TextField
                        id={props.input.name}
                        label={"Invoice #"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? true
                            : false
                        }
                        helperText={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? props.meta.error || props.meta.submitError
                            : undefined
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="cost">
                {(props) => (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.fieldContainer}
                      error={
                        props.meta.error && props.meta.touched
                          ? !!props.meta.error
                          : undefined
                      }
                    >
                      <TextField
                        id={props.input.name}
                        label={"Cost"}
                        variant="outlined"
                        name={props.input.name}
                        type="number"
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? true
                            : false
                        }
                        helperText={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? props.meta.error || props.meta.submitError
                            : undefined
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="comments">
                {(props) => (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.fieldContainer}
                      error={
                        props.meta.error && props.meta.touched
                          ? !!props.meta.error
                          : undefined
                      }
                    >
                      <TextField
                        id={props.input.name}
                        label={"Comments"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? true
                            : false
                        }
                        helperText={
                          (props.meta.error || props.meta.submitError) &&
                          props.meta.touched
                            ? props.meta.error || props.meta.submitError
                            : undefined
                        }
                        multiline
                        rows={4}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Grid item xs={12}>
                {(errors as any)[FORM_ERROR] && (
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <Alert severity="error">
                      {(errors as any)[FORM_ERROR]}
                    </Alert>
                  </div>
                )}

                <ClaimAttachments
                  title="Attachments"
                  attachments={attachments}
                  onAdded={(attachment) => {
                    const newAttachments = Object.assign([], attachments);
                    newAttachments.push(attachment);
                    setAttachments(newAttachments);
                  }}
                  onRemoved={(attachment) => {
                    const index = attachments.findIndex(
                      (a) => a.id === attachment.id,
                    );
                    const newAttachments = Object.assign([], attachments);
                    if (index >= 0) {
                      newAttachments.splice(index, 1);
                    }
                    setAttachments(newAttachments);

                    const newRemovedAttachments = Object.assign(
                      [],
                      removedAttachments,
                    );
                    newRemovedAttachments.push(attachment.id);
                    setRemovedAttachments(newRemovedAttachments);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "space-between", padding: 24 }}
          >
            <Button
              type="submit"
              disabled={isSaving}
              variant="contained"
              color="primary"
            >
              {props.sublet?.id ? "Update" : "Add"}
            </Button>
            <Button onClick={() => props.onCancel()} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      )}
    />
  );
}

export { LaborForm, PartForm, SubletForm };
