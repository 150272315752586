import React, {
  useState,
  useEffect,
  useRef,
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';

import {
  APIError,
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import {
  Customer,
  WarrantyClaim,
  User,
  Dealer,
  Session,
  Boat,
  ClaimLaborItem,
  ClaimPartItem,
  ClaimSubletItem,
  LaborCategory,
  JobDescription,
  ClaimAttachment
} from '../../types';

import localization from '../../utils/localizations';

import CountrySelect from './fields/CountrySelect';
import StateSelect from './fields/StateSelect';
import HinField from './fields/HinField';
import ClaimAttachments from '../collections/ClaimAttachments';
import {
  LaborForm,
  PartForm,
  SubletForm
} from './WarrantyClaimLineItemForms';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles({
  root: {
    padding: 10,
    borderRadius: 4,
  },
  form: {

  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  closeButton: {
    padding: 4,
  },
  downloadButton: {
    marginRight: 6,
  },
  lineItem: {
    minWidth: '90%',
    marginBottom: 12,
  },
  lineItemTitle: {
    fontSize: 14
  },
  bgWhite: {
    backgroundColor: '#ffffff',
  }
});

interface FormValues {
  dealer: string;
  customer: string;
  dealer_contact: string;
  dealer_email: string;
  dealer_address: string;
  dealer_city: string;
  dealer_state: string;
  dealer_country: string;
  dealer_zip: string;
  owner_contact: string;
  owner_email: string;
  owner_phone: string;
  owner_address: string;
  owner_city: string;
  owner_state: string;
  owner_country: string;
  owner_zip: string;
  date_of_failure: string;
  date_of_check?: string;
  seafox_comments: string;
  dealer_comments: string;
  status: string;
  denial_reason: string;
  invoice_number: string;
  reimb_acct: string;
  serial_number: string;
  engine_serial: string;
  engine_serial_2: string;
  engine_serial_3: string;
  approved_labor_hourly_rate: string;
}

export default function WarrantyClaimForm(props: {
  dealerId: string;
  customer?: Customer;
  claim: WarrantyClaim|null|undefined;
  onSave: (claim: WarrantyClaim) => void;
  onCancel: () => void;
  className?: string;
}) {const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [editedLabor, setEditedLabor] = useState<ClaimLaborItem|undefined>(undefined);
  const [editedPart, setEditedPart] = useState<ClaimPartItem|undefined>(undefined);
  const [editedSublet, setEditedSublet] = useState<ClaimSubletItem|undefined>(undefined);

  const [showCustomerPicker, setShowCustomerPicker] = useState(false);
  const [currentForm, setCurrentForm] = useState<any>(undefined);

  const [warrantyCards, setWarrantyCards] = useState<ClaimAttachment[]>([]);
  const [attachments, setAttachments] = useState<ClaimAttachment[]>([]);
  const [labors, setLabors] = useState<ClaimLaborItem[]>([]);
  const [parts, setParts] = useState<ClaimPartItem[]>([]);
  const [sublets, setSublets] = useState<ClaimSubletItem[]>([]);
  const [removedWarrantyCards, setRemovedWarrantyCards] = useState<string[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);
  const [removedLabors, setRemovedLabors] = useState<string[]>([]);
  const [removedParts, setRemovedParts] = useState<string[]>([]);
  const [removedSublets, setRemovedSublets] = useState<string[]>([]);

  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [allDealers, setAllDealers] = useState(((window as any)._cached && (window as any)._cached['all_dealers'] ? (window as any)._cached['all_dealers']: []) as Dealer[]);
  const [customers, setCustomers] = useState([] as Customer[]);
  const [selectedDealer, setSelectedDealer] = useState(null as Dealer|null);
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [customersMeta, setCustomersMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0
  });

  const customersApi = useRef(new BaseAPI);
  const loadCustomers = async (dealer: Dealer, page?: number) => {
    const api = customersApi.current;
    let url = `warranty-customers/`;
    let kwargs: any = {};
    kwargs.dealer = dealer.id;
    if (page) kwargs.page = page;
    url = `${url}?${new URLSearchParams(kwargs).toString()}`;
    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        if (page && (page > customersMeta.page)) {
          const results = data.results as Customer[];
          setCustomers(Object.assign([], customers).concat(results));
        }
        else {
          setCustomers(data.results as Customer[]);
        }

        setCustomersMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next
        });
      }
    } catch (error) {
      console.error(error);
    }

  };

  const loadAllDealers = async () => {

    const api = new BaseAPI();
    try {
      const data = await api.get('all-dealers/');
      if (data instanceof Array) {
        setAllDealers(data as Dealer[]);

        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached['all_dealers']) (window as any)._cached['all_dealers'] = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (allDealers.length === 0) loadAllDealers();
  }, []);

  useEffect(() => {
    if (props.claim) {
      setWarrantyCards(props.claim.warranty_cards);
      setAttachments(props.claim.attachments);
      setLabors(props.claim.labors);
      setParts(props.claim.parts);
      setSublets(props.claim.sublets);
      setRemovedWarrantyCards([]);
      setRemovedAttachments([]);
      setRemovedLabors([]);
      setRemovedParts([]);
      setRemovedSublets([]);
    }
  }, [props.claim]);

  useEffect(() => {
    if (props.claim?.dealer.id) {
      customersApi.current.abort();
      setSelectedDealer(props.claim.dealer);
    }
  }, [props.claim?.dealer.id]);

  useEffect(() => {
    if (customersMeta.page && customersMeta.next) {
      let d = selectedDealer ? selectedDealer : props.claim?.dealer;
      if (d) loadCustomers(d, customersMeta.page + 1);
    }
  }, [customersMeta.page, customersMeta.next]);

  const onSubmit = async (values: FormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();

    let success = false;
    let id: number|undefined = undefined;
    let claim: WarrantyClaim|undefined = undefined;

    if (!props.claim) {
      try {
        const [result, response, error] = await api.post({
          dealer: values.dealer,
          customer: values.customer,
          dealer_contact: values.dealer_contact,
          dealer_email: values.dealer_email,
          dealer_address: values.dealer_address,
          dealer_city: values.dealer_city,
          dealer_state: values.dealer_state,
          dealer_country: values.dealer_country,
          dealer_zip: values.dealer_zip,
          owner_contact: values.owner_contact,
          owner_email: values.owner_email,
          owner_phone: values.owner_phone,
          owner_address: values.owner_address,
          owner_city: values.owner_city,
          owner_state: values.owner_state,
          owner_country: values.owner_country,
          owner_zip: values.owner_zip,
          date_of_failure: values.date_of_failure,
          date_of_check: values.date_of_check,
          seafox_comments: values.seafox_comments,
          dealer_comments: values.dealer_comments,
          labors: labors,
          removed_labors: removedLabors,
          parts: parts,
          removed_parts: removedParts,
          sublets: sublets,
          removed_sublets: removedSublets,
          status: values.status,
          attachment_ids: attachments.map(attachment => attachment.id),
          removed_attachment_ids: removedAttachments,
          warranty_card_ids: warrantyCards.map(attachment => attachment.id),
          removed_warranty_card_ids: removedWarrantyCards,
          serial_number: values.serial_number,
          engine_serial: values.engine_serial,
          engine_serial_2: values.engine_serial_2,
          engine_serial_3: values.engine_serial_3,
          denial_reason: values.denial_reason,
          invoice_number: values.invoice_number,
          reimb_acct: values.reimb_acct,
          approved_labor_hourly_rate: values.approved_labor_hourly_rate ? parseFloat(values.approved_labor_hourly_rate) : undefined,
        }, 'warranty-claims/create_claim/');
        id = result.id;
        claim = result as WarrantyClaim;
        console.log('result', result);

      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
      setIsSaving(false);

      if (id && claim) {
        props.onSave(claim);
      }
    }
    else {
      try {
        const [result, response, error] = await api.post({
          id: props.claim.id,
          dealer: values.dealer,
          customer: values.customer,
          dealer_contact: values.dealer_contact,
          dealer_email: values.dealer_email,
          dealer_address: values.dealer_address,
          dealer_city: values.dealer_city,
          dealer_state: values.dealer_state,
          dealer_country: values.dealer_country,
          dealer_zip: values.dealer_zip,
          owner_contact: values.owner_contact,
          owner_email: values.owner_email,
          owner_phone: values.owner_phone,
          owner_address: values.owner_address,
          owner_city: values.owner_city,
          owner_state: values.owner_state,
          owner_country: values.owner_country,
          owner_zip: values.owner_zip,
          date_of_failure: values.date_of_failure,
          date_of_check: values.date_of_check,
          seafox_comments: values.seafox_comments,
          dealer_comments: values.dealer_comments,
          labors: labors,
          removed_labors: removedLabors,
          parts: parts,
          removed_parts: removedParts,
          sublets: sublets,
          removed_sublets: removedSublets,
          status: values.status,
          attachment_ids: attachments.map(attachment => attachment.id),
          removed_attachment_ids: removedAttachments,
          warranty_card_ids: warrantyCards.map(attachment => attachment.id),
          removed_warranty_card_ids: removedWarrantyCards,
          serial_number: values.serial_number,
          engine_serial: values.engine_serial,
          engine_serial_2: values.engine_serial_2,
          engine_serial_3: values.engine_serial_3,
          denial_reason: values.denial_reason,
          invoice_number: values.invoice_number,
          reimb_acct: values.reimb_acct,
          approved_labor_hourly_rate: values.approved_labor_hourly_rate ? parseFloat(values.approved_labor_hourly_rate) : undefined,
        }, `warranty-claims/${props.claim.id}/edit_claim/`);
        id = result.id;
        claim = result as WarrantyClaim;
        console.log('result', result);
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          console.log((e as APIError).errorData);
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
      setIsSaving(false);

      if (id && claim) {
        props.onSave(claim);
      }
    }

    setIsSaving(false);
  }

  const onValidate = (values: FormValues) => {
    const errors: any = {}

    const isAdmin = profile.role === 'admin';

    if (!values.dealer) errors.dealer = "Required";
    if (!values.customer && !isAdmin) errors.customer = "Required";
    if (!values.dealer_contact) errors.dealer_contact = "Required";
    if (!values.dealer_email) errors.dealer_email = "Required";
    if (!values.dealer_address) errors.dealer_address = "Required";
    if (!values.dealer_city) errors.dealer_city = "Required";
    if (!values.dealer_state) errors.dealer_state = "Required";
    if (!values.dealer_country) errors.dealer_country = "Required";
    if (!values.dealer_zip) errors.dealer_zip = "Required";
    if (!values.date_of_failure) errors.date_of_failure = "Required";
    if ((values.status === 'denied') && (!values.denial_reason)) errors.denial_reason = "Required";
    if (!values.dealer_comments && profile.role ==='customer') errors.dealer_comments = "Required";
    if (values.approved_labor_hourly_rate && isNaN(parseFloat(values.approved_labor_hourly_rate))) errors.approved_labor_hourly_rate('Please enter a valid number');
    return errors;
  }

  const editLabor = (labor?: ClaimLaborItem) => {
    if (labor) {
      setEditedLabor(labor);
    }
    else {
      setEditedLabor({
        local_id: moment().format(),
        id: "",
        labor_category: {id: "", name: ""},
        job_description: {id: "", name: "", flat_rate_code: "", labor_hours: "", category: {id: "", name: ""}},
        labor_hours: "",
        comments: "",
        attachments: [],
        created_at: moment().format(),
        updated_at: moment().format(),
      });
    }
  }

  const editPart = (part?: ClaimPartItem) => {
    if (part) {
      setEditedPart(part);
    }
    else {
      setEditedPart({
        local_id: moment().format(),
        id: "",
        type: "ship",
        part_number: "",
        description: "",
        unit_cost: "",
        quantity: 1,
        total_cost: "",
        comments: "",
        attachments: [],
        created_at: moment().format(),
        updated_at: moment().format(),
      });
    }
  }

  const editSublet = (sublet?: ClaimSubletItem) => {
    if (sublet) {
      setEditedSublet(sublet);
    }
    else {
      setEditedSublet({
        local_id: moment().format(),
        id: "",
        company_name: "",
        invoice_number: "",
        cost: "",
        description: "",
        comments: "",
        attachments: [],
        created_at: moment().format(),
        updated_at: moment().format(),
      });
    }
  }

  const claim = props.claim;


  let showCostEditor = false;

  if (profile.role === 'admin') {
    showCostEditor = true;
  }

  if (claim && claim.date_submitted && (moment(claim.date_submitted) < moment('2022-09-05'))) {
    showCostEditor = false;
  }

  let approvedLaborHourlyRate = undefined;
  if (claim && claim.dealer) {
    approvedLaborHourlyRate = claim.dealer.labor_rate;
  }
  if (claim && claim.approved_labor_hourly_rate) {
    approvedLaborHourlyRate = parseFloat(`${claim.approved_labor_hourly_rate}`);
  }

  let customer: Customer|undefined = undefined;
  let owner_contact = '';
  let owner_email = '';
  let owner_phone = '';
  let owner_address = '';
  let owner_city = '';
  let owner_state = '';
  let owner_country = '';
  let owner_zip = '';
  let serial_number = '';
  let engine_serial = '';
  let engine_serial_2 = '';
  let engine_serial_3 = '';

  if (props.customer) {
    customer = props.customer;
    owner_contact = `${customer.first_name} ${customer.last_name}`;
    owner_email = customer.email;
    owner_phone = customer.phone;
    owner_address = customer.address_line_1;
    owner_city = customer.city;
    owner_state = customer.state;
    owner_country = customer.country;
    owner_zip = customer.zip;
    if (customer.boat) {
      serial_number = customer.boat.serial_number;
      engine_serial = customer.boat.engine_serial;
      engine_serial_2 = customer.boat.engine_serial_2;
      engine_serial_3 = customer.boat.engine_serial_3;
    }
  }
  if (claim?.customer) {
    customer = claim.customer;
  }
  if (claim) {
    owner_contact = claim.owner_contact;
    owner_email = claim.owner_email;
    owner_phone = claim.owner_phone;
    owner_address = claim.owner_address;
    owner_city = claim.owner_city;
    owner_state = claim.owner_state;
    owner_country = claim.owner_country;
    owner_zip = claim.owner_zip;
    serial_number = claim.serial_number;
    engine_serial = claim.engine_serial;
    engine_serial_2 = claim.engine_serial_2;
    engine_serial_3 = claim.engine_serial_3;
  }

  return (
    <div className={`${classes.root} ${classes.bgWhite} ${props.className ? props.className : ''}`}>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        dealer: claim ? claim.dealer.id : props.dealerId,
        customer: customer ? customer.id : undefined,
        dealer_contact: claim ? claim.dealer_contact : '',
        dealer_email: claim ? claim.dealer_email : '',
        dealer_address: claim ? claim.dealer_address : '',
        dealer_city: claim ? claim.dealer_city : '',
        dealer_state: claim ? claim.dealer_state : '',
        dealer_country: claim ? claim.dealer_country : 'United States',
        dealer_zip: claim ? claim.dealer_zip : '',
        owner_contact: owner_contact,
        owner_email: owner_email,
        owner_phone: owner_phone,
        owner_address: owner_address,
        owner_city: owner_city,
        owner_state: owner_state,
        owner_country: owner_country,
        owner_zip: owner_zip,
        date_of_failure: claim ? claim.date_of_failure : moment().format('YYYY-MM-DD'),
        date_of_check: claim ? claim.date_of_check : undefined,
        seafox_comments: claim ? claim.seafox_comments : '',
        dealer_comments: claim ? claim.dealer_comments : '',
        serial_number: serial_number,
        engine_serial: engine_serial,
        engine_serial_2: engine_serial_2,
        engine_serial_3: engine_serial_3,
        status: claim ? claim.status : 'wip',
        denial_reason: claim ? claim.denial_reason : '',
        invoice_number: claim ? claim.invoice_number : '',
        reimb_acct: claim ? claim.reimb_acct : '',
        approved_labor_hourly_rate: approvedLaborHourlyRate ? parseFloat(`${approvedLaborHourlyRate}`).toFixed(2) : '',
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors, submitError }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container>

            <Grid container spacing={3}>
              <Field name="dealer">
                {props => (
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Dealer</InputLabel>
                      <Select
                        id={props.input.name}
                        label={'Dealer'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={(event) => {
                          props.input.onChange(event);
                          allDealers.filter((dealer) => {
                            if (dealer.id == event.target.value) {
                              customersApi.current.abort();
                              setSelectedDealer(dealer);

                              form.change('dealer_contact', dealer.customer_name);
                              form.change('approved_labor_hourly_rate', dealer.labor_rate.toFixed(2));
                              if (dealer.addresses.length > 0) {
                                const address = dealer.addresses[0];

                                if (address.email) {
                                  form.change('dealer_email', address.email);
                                }
                                else {
                                  form.change('dealer_email', dealer.email);
                                }

                                form.change('dealer_address', address.address_line_1);
                                form.change('dealer_city', address.city);
                                form.change('dealer_state', address.state);
                                form.change('dealer_country', address.country);
                                form.change('dealer_zip', address.zip);
                              }
                            }
                          })
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      >
                        <ListSubheader>Active Dealers</ListSubheader>
                        {allDealers.filter(dealer => dealer.active).map((dealer, i) => (
                          <MenuItem key={`dealer-menu-item-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                        ))}
                        <ListSubheader>Inactive Dealers</ListSubheader>
                        {allDealers.filter(dealer => !dealer.active).map((dealer, i) => (
                          <MenuItem key={`dealer-menu-item-inactive-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                        ))}
                      </Select>
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="customer">
                {props => (
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Warranty Registration</InputLabel>
                      <Select
                        id={props.input.name}
                        label={'Warranty Registration'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        disabled
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        onClick={() => {
                          setShowCustomerPicker(true);
                          setCurrentForm(form);
                        }}
                      >
                        {!!customer &&
                          <MenuItem value={customer.id}>{customer.first_name} {customer.last_name} | {customer.boat.serial_number} | {customer.boat.model}</MenuItem>
                        }
                        {customers.map((customer, i) => (
                          <MenuItem key={`customer-menu-item-${i}`} value={customer.id}>{customer.first_name} {customer.last_name} | {customer.boat.serial_number} | {customer.boat.model}</MenuItem>
                        ))}
                      </Select>
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

            </Grid>

            <Grid container spacing={3}>

            <Field name="serial_number">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <HinField
                        id={props.input.name}
                        label={'Serial Number / HIN'}
                        placeholder="Please fill out the warranty registration field first"
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        readOnly={profile.role !== 'admin'}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="engine_serial">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Engine #1 Serial Number'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        InputProps={{
                          readOnly: profile.role !== 'admin',
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="engine_serial_2">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Engine #2 Serial Number'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        InputProps={{
                          readOnly: profile.role !== 'admin',
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="engine_serial_3">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Engine #3 Serial Number'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        InputProps={{
                          readOnly: profile.role !== 'admin',
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

            </Grid>

            <Grid container spacing={3}>
              {(profile.role === 'admin') &&
              <Field name="status">
                {props => (
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        id={props.input.name}
                        label={'Status'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        disabled={profile.role != 'admin'}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      >
                        <MenuItem value="wip">Work in Progress</MenuItem>
                        {(profile.role != 'admin') && <MenuItem value="pending">Ready for Submission</MenuItem>}
                        {(profile.role === 'admin') && <MenuItem value="pending">Pending</MenuItem>}
                        {(profile.role === 'admin') && <MenuItem value="approved">Approved</MenuItem>}
                        {(profile.role === 'admin') && <MenuItem value="paid">Paid</MenuItem>}
                        {(profile.role === 'admin') && <MenuItem value="denied">Denied</MenuItem>}
                        {(profile.role === 'admin') && <MenuItem value="canceled">Canceled</MenuItem>}
                        {(profile.role === 'admin') && <MenuItem value="converted">Converted</MenuItem>}
                        {(profile.role === 'admin') && <MenuItem value="rtf">RTF</MenuItem>}
                        {(profile.role === 'admin') && <MenuItem value="closed">Closed</MenuItem>}
                      </Select>
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>}

              <Field name="date_of_failure">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Date of Failure'}
                        variant="outlined"
                        type="date"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            {(profile.role === 'admin') &&
            <Field name="denial_reason">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Denial Reason'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>}


            <Grid container spacing={3}>
              {(profile.role === 'admin') &&
              <Field name="invoice_number">
                {props => (
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Invoice #'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>}

              {(profile.role === 'admin') &&
              <Field name="reimb_acct">
                {props => (
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Check #'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>}

              {(profile.role === 'admin') &&
              <Field name="date_of_check">
                {props => (
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Date of Check'}
                        variant="outlined"
                        type="date"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>}
            </Grid>

            {(profile.role === 'admin') &&
            <Field name="seafox_comments">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Seafox Comment'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>}

            <Field name="dealer_comments">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Dealer Comment'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant='h5' style={{paddingBottom: 12}}>Owner Information</Typography>
              </Grid>
              <Grid container spacing={3}>
                <Field name="owner_contact">
                  {props => (
                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Contact'}
                          placeholder="Please fill out the warranty registration field first"
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          InputProps={{
                            readOnly: profile.role !== 'admin',
                          }}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="owner_email">
                  {props => (
                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Email'}
                          variant="outlined"
                          type="email"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          InputProps={{
                            readOnly: profile.role !== 'admin',
                          }}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="owner_phone">
                  {props => (
                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Phone'}
                          variant="outlined"
                          type="tel"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          InputProps={{
                            readOnly: profile.role !== 'admin',
                          }}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

              </Grid>

              <Field name="owner_address">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Address'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        InputProps={{
                          readOnly: profile.role !== 'admin',
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Grid container spacing={3}>
                <Field name="owner_city">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'City'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          InputProps={{
                            readOnly: profile.role !== 'admin',
                          }}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="owner_country">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>Country</InputLabel>
                        <CountrySelect
                          id={props.input.name}
                          label={'Country'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          readOnly={profile.role !== 'admin'}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                        }
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="owner_state">
                  {props => (
                    <Grid item xs={3}>
                      {values.owner_country === 'United States' &&
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>State</InputLabel>
                        <StateSelect
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          readOnly={profile.role !== 'admin'}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                        }
                      </FormControl>}
                      {values.owner_country !== 'United States' &&
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          InputProps={{
                            readOnly: profile.role !== 'admin',
                          }}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                      }
                    </Grid>
                  )}
                </Field>

                <Field name="owner_zip">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Zip / Postal Code'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          InputProps={{
                            readOnly: profile.role !== 'admin',
                          }}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant='h5' style={{paddingBottom: 12}}>Dealer Information</Typography>
              </Grid>
              <Grid item xs={12}>
                {(!!selectedDealer && selectedDealer.addresses.length > 0) &&
                <div key={`customer-address-${values.dealer}`} style={{marginTop: 10, marginBottom: 40}}>
                  <div style={{display: 'flex'}}>
                {selectedDealer.addresses.map((address, i) => (
                  <Card key={`address-selector-${i}`} style={{marginRight: 20}}>
                    <CardContent>
                      <Typography variant="body2">
                        <span>{address.address_line_1}</span><br/>
                        <span>{address.city}</span> <span>{address.state}</span> <span>{address.zip}</span><br/>
                        <span>{address.country}</span>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={() => {
                        form.change('dealer_contact', selectedDealer.customer_name);
                        if (address.email) {
                          form.change('dealer_email', address.email);
                        }
                        else {
                          form.change('dealer_email', selectedDealer.email);
                        }

                        form.change('dealer_address', address.address_line_1);
                        form.change('dealer_city', address.city);
                        form.change('dealer_country', address.country);
                        form.change('dealer_state', address.state);
                        form.change('dealer_zip', address.zip);
                      }}>Use</Button>
                    </CardActions>
                  </Card>
                ))}
                </div>
                </div>}

              </Grid>
              <Grid container spacing={3}>
                <Field name="dealer_contact">
                  {props => (
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Contact'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_email">
                  {props => (
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Email'}
                          variant="outlined"
                          type="email"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>

              <Field name="dealer_address">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Address'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Grid container spacing={3}>
                <Field name="dealer_city">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'City'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_country">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>Country</InputLabel>
                        <CountrySelect
                          id={props.input.name}
                          label={'Country'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                        }
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_state">
                  {props => (
                    <Grid item xs={3}>
                      {values.dealer_country === 'United States' &&
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>State</InputLabel>
                        <StateSelect
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                        }
                      </FormControl>}
                      {values.dealer_country !== 'United States' &&
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                      }
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_zip">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Zip / Postal Code'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>
            </Grid>

            {((errors as any)[FORM_ERROR]) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">{(errors as any)[FORM_ERROR]}</Alert>
              </div>
            </Grid>}

            <Grid item xs={12}>
              <ClaimAttachments
                title="Attachments"
                attachments={attachments}
                onAdded={(attachment) => {
                  const newAttachments = Object.assign([], attachments);
                  newAttachments.push(attachment);
                  setAttachments(newAttachments);
                }}
                onRemoved={(attachment) => {
                  const newRemovedAttachments = Object.assign([], removedAttachments);
                  newRemovedAttachments.push(attachment.id);
                  setRemovedAttachments(newRemovedAttachments);
                }}
              />
            </Grid>

            {!!showCostEditor &&
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant='h5' style={{paddingBottom: 12, paddingTop: 24}}>Cost</Typography>
              </Grid>

            <Grid container spacing={3}>
              <Field name="approved_labor_hourly_rate">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Labor Hourly Rate'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        type="number"
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            </Grid>}

            <Grid item xs={12}>
              <div style={{paddingTop: 30}}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => editLabor()}
                >Add Labor</Button>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  style={{marginLeft: 10}}
                  onClick={() => editPart()}
                >Add Parts</Button>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  style={{marginLeft: 10}}
                  onClick={() => editSublet()}
                >Add Sublet</Button>
              </div>
              <LineItems
                labors={labors}
                parts={parts}
                sublets={sublets}
                onEditLabor={(labor) => setEditedLabor(labor)}
                onEditPart={(part) => setEditedPart(part)}
                onEditSublet={(sublet) => setEditedSublet(sublet)}
                onRemoveLabor={(labor) => {
                  setLabors(labors.filter((l) => {
                    if (labor.id && labor.id === l.id) return false;
                    else if (labor.local_id && labor.local_id === l.local_id) return false;
                    return true;
                  }));
                  if (labor.id) {
                    const newRemovedLabors = Object.assign([], removedLabors);
                    newRemovedLabors.push(labor.id);
                    setRemovedLabors(newRemovedLabors);
                  }
                }}
                onRemovePart={(part) => {
                  setParts(parts.filter((p) => {
                    if (part.id && part.id === p.id) return false;
                    else if (part.local_id && part.local_id === p.local_id) return false;
                    return true;
                  }));
                  if (part.id) {
                    const newRemovedParts = Object.assign([], removedParts);
                    newRemovedParts.push(part.id);
                    setRemovedParts(newRemovedParts);
                  }
                }}
                onRemoveSublet={(sublet) => {
                  setSublets(sublets.filter((s) => {
                    if (sublet.id && sublet.id === s.id) return false;
                    else if (sublet.local_id && sublet.local_id === s.local_id) return false;
                    return true;
                  }));
                  if (sublet.id) {
                    const newRemovedSublets = Object.assign([], removedSublets);
                    newRemovedSublets.push(sublet.id);
                    setRemovedSublets(newRemovedSublets);
                  }
                }}
              />
            </Grid>

            {(Object.keys(errors as any).length > 0) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
              </div>
            </Grid>}

            <Grid item xs={9}>
              <div>
                {profile.role === 'admin' &&
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    type="submit"
                    disabled={isSaving}
                  >Save</Button>}
                {profile.role !== 'admin' &&
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    type="submit"
                    disabled={isSaving}
                    onClick={(e) => {
                      form.change('status', 'wip');
                    }}
                  >Save as WIP</Button>}
                {profile.role !== 'admin' &&
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<SendIcon />}
                    type="submit"
                    disabled={isSaving}
                    style={{marginLeft: 20}}
                    onClick={(e) => {
                      form.change('status', 'pending');
                    }}
                  >Submit Claim</Button>}
                {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
                {!!(labors.length || parts.length || sublets.length) &&
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    style={{marginLeft: 20}}
                    onClick={() => editLabor()}
                  >Add Labor</Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    style={{marginLeft: 10}}
                    onClick={() => editPart()}
                  >Add Parts</Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    style={{marginLeft: 10}}
                    onClick={() => editSublet()}
                  >Add Sublet</Button>
                </>
                }
              </div>
            </Grid>
            <Grid item xs={3} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />

    <Dialog open={!!editedLabor} maxWidth="md" fullWidth onClose={() => setEditedLabor(undefined)}>
      <LaborForm
        labor={editedLabor}
        onSave={(labor) => {
          setEditedLabor(undefined);
          const newLabors: ClaimLaborItem[] = Object.assign([], labors);
          let found = false;
          newLabors.find((l) => {
            let localFound = false;
            if (labor.id && (l.id === labor.id)) localFound = true;
            else if (labor.local_id && (l.local_id === labor.local_id)) localFound = true;
            if (localFound) {
              l.labor_category = labor.labor_category;
              l.job_description = labor.job_description;
              l.labor_hours = labor.labor_hours;
              l.comments = labor.comments;
              l.attachments = labor.attachments;
              found = true;
            }
          });
          if (!found) newLabors.push(labor);
          setLabors(newLabors);
          console.log(newLabors);
        }}
        onCancel={() => {
          setEditedLabor(undefined)
        }}
      />
    </Dialog>
    <Dialog open={!!editedPart} maxWidth="md" fullWidth onClose={() => setEditedPart(undefined)}>
      <PartForm
        part={editedPart}
        onSave={(part) => {
          setEditedPart(undefined);
          const newParts: ClaimPartItem[] = Object.assign([], parts);
          let found = false;
          newParts.find((p) => {
            let localFound = false;
            if (part.id && (p.id === part.id)) localFound = true;
            else if (part.local_id && (p.local_id === part.local_id)) localFound = true;
            if (localFound) {
              p.part_number = part.part_number;
              p.type = part.type;
              p.description = part.description;
              p.unit_cost = part.unit_cost;
              p.quantity = part.quantity;
              p.total_cost = part.total_cost;
              p.comments = part.comments;
              p.attachments = part.attachments;
              found = true;
            }
          });
          if (!found) newParts.push(part);
          setParts(newParts);
          console.log(newParts);
        }}
        onCancel={() => {
          setEditedPart(undefined)
        }}
      />
    </Dialog>
    <Dialog open={!!editedSublet} maxWidth="md" fullWidth onClose={() => setEditedSublet(undefined)}>
      <SubletForm
        sublet={editedSublet}
        onSave={(sublet) => {
          setEditedSublet(undefined);
          const newSublets: ClaimSubletItem[] = Object.assign([], sublets);
          let found = false;
          newSublets.find((s) => {
            let localFound = false;
            if (sublet.id && (s.id === sublet.id)) localFound = true;
            else if (sublet.local_id && (s.local_id === sublet.local_id)) localFound = true;
            if (localFound) {
              s.company_name = sublet.company_name;
              s.invoice_number = sublet.invoice_number;
              s.cost = sublet.cost;
              s.description = sublet.description;
              s.comments = sublet.comments;
              s.attachments = sublet.attachments;
              found = true;
            }
          });
          if (!found) newSublets.push(sublet);
          setSublets(newSublets);
          console.log(newSublets);
        }}
        onCancel={() => {
          setEditedSublet(undefined)
        }}
      />
    </Dialog>

    <Dialog
      open={showCustomerPicker}
      maxWidth='lg'
      fullWidth
      onClose={() => {
        setShowCustomerPicker(false);
      }}
    >
      <DialogTitle id="form-dialog-title">Find Warranty Registrations</DialogTitle>
      <FindCustomerForm
        dealer={selectedDealer}
        onSave={(found) => {
          if (currentForm) {
            currentForm.change('customer', found.customer);
            currentForm.change('owner_contact', `${found.customerData.first_name} ${found.customerData.last_name}`);
            currentForm.change('owner_email', found.customerData.email);
            currentForm.change('owner_phone', found.customerData.phone);
            currentForm.change('owner_address', found.customerData.address_line_1);
            currentForm.change('owner_city', found.customerData.city);
            currentForm.change('owner_state', found.customerData.state);
            currentForm.change('owner_country', found.customerData.country);
            currentForm.change('owner_zip', found.customerData.zip);

            currentForm.change('serial_number', found.customerData.boat.serial_number);
            currentForm.change('engine_serial', found.customerData.boat.engine_serial);
            currentForm.change('engine_serial_2', found.customerData.boat.engine_serial_2);
            currentForm.change('engine_serial_3', found.customerData.boat.engine_serial_3);
            if (found.customers) setCustomers(found.customers);
          }
          setShowCustomerPicker(false);
        }}
        onCancel={() => {
          setShowCustomerPicker(false);
        }}
      />
    </Dialog>
    </div>
  );
}


function LaborLineItem(props: {
  counter?: number;
  labor: ClaimLaborItem;
  onEdit: (labor: ClaimLaborItem) => void;
  onRemove: (labor: ClaimLaborItem) => void;
}) {
  const classes = useStyles();
  const labor = props.labor;

  const [attachments, setAttachments] = useState<ClaimAttachment[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);

  useEffect(() => {
    if (props.labor) {
      setAttachments(props.labor.attachments);
      setRemovedAttachments([]);
    }
  }, [props.labor]);

  return (
    <Card className={classes.lineItem} variant="outlined">
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <CardContent>
            <Typography className={classes.lineItemTitle} color="textSecondary" gutterBottom>
              {props.counter ? `#${props.counter} — ` : ''} Line Type: Labor
            </Typography>
            <Typography variant="body2">
              Labor Category: {labor.labor_category?.name} <br />
              Job Description: {labor.job_description?.name} <br />
              Flat Rate Code: {labor.job_description?.flat_rate_code} <br />
              Labor Hours: {labor.labor_hours ? parseFloat(labor.labor_hours).toFixed(2) : '0.00'} <br />
            </Typography>
            <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
              Comments: {labor.comments}
            </Typography>
          </CardContent>
          <CardActions>
            <div>
              <Button
                size="small"
                color="primary"
                onClick={() => props.onEdit(labor)}
                style={{marginRight: 10}}
              >Edit</Button>
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  if (window.confirm('Remove this item?')) {
                    props.onRemove(labor);
                  }
                }}
              >Delete</Button>
            </div>
          </CardActions>
        </Grid>
        <Grid item xs={4}>
          <ClaimAttachments
            title="Attachments"
            attachments={attachments}
            readOnly
          />
        </Grid>
      </Grid>
    </Card>
  );
}


function PartLineItem(props: {
  counter?: number;
  part: ClaimPartItem;
  onEdit: (part: ClaimPartItem) => void;
  onRemove: (part: ClaimPartItem) => void;
}) {
  const classes = useStyles();
  const part = props.part;

  const [attachments, setAttachments] = useState<ClaimAttachment[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);

  useEffect(() => {
    if (props.part) {
      setAttachments(props.part.attachments);
      setRemovedAttachments([]);
    }
  }, [props.part]);

  let unitCost = parseFloat(part.unit_cost);
  if (isNaN(unitCost)) unitCost = 0;

  return (
    <Card className={classes.lineItem} variant="outlined">
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <CardContent>
            <Typography className={classes.lineItemTitle} color="textSecondary" gutterBottom>
            {props.counter ? `#${props.counter} — ` : ''} Line Type: Parts
            </Typography>
            <Typography variant="body2">
              Ship / Credit: {part.type} <br />
              Quantity: {part.quantity} <br />
              Unit Cost: {unitCost.toFixed(2)} <br />
              Total Cost: {(unitCost * part.quantity).toFixed(2)} <br />
              Part Number: {part.part_number} <br />
              Description: {part.description}
            </Typography>
            <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
              Comments: {part.comments}
            </Typography>
          </CardContent>
          <CardActions>
            <div>
              <Button
                color="primary"
                size="small"
                onClick={() => props.onEdit(part)}
                style={{marginRight: 10}}
              >Edit</Button>
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  if (window.confirm('Remove this item?')) {
                    props.onRemove(part);
                  }
                }}
              >Delete</Button>
            </div>
          </CardActions>
        </Grid>
        <Grid item xs={4}>
          <ClaimAttachments
            title="Attachments"
            attachments={attachments}
            readOnly
          />
        </Grid>
      </Grid>
    </Card>
  );
}


function SubletLineItem(props: {
  counter?: number;
  sublet: ClaimSubletItem;
  onEdit: (sublet: ClaimSubletItem) => void;
  onRemove: (sublet: ClaimSubletItem) => void;
}) {
  const classes = useStyles();
  const sublet = props.sublet;

  const [attachments, setAttachments] = useState<ClaimAttachment[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);

  useEffect(() => {
    if (props.sublet) {
      setAttachments(props.sublet.attachments);
      setRemovedAttachments([]);
    }
  }, [props.sublet]);

  let cost = parseFloat(sublet.cost);
  if (isNaN(cost)) cost = 0;

  return (
    <Card className={classes.lineItem} variant="outlined">
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <CardContent>
            <Typography className={classes.lineItemTitle} color="textSecondary" gutterBottom>
            {props.counter ? `#${props.counter} — ` : ''} Line Type: Sublet
            </Typography>
            <Typography variant="body2">
              Description: {sublet.description} <br />
              Company Name: {sublet.company_name} <br />
              Invoice #: {sublet.invoice_number} <br />
              Cost: {sublet.cost} <br />
            </Typography>
            <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
              Comments: {sublet.comments}
            </Typography>
          </CardContent>
          <CardActions>
            <div>
              <Button
                color="primary"
                size="small"
                onClick={() => props.onEdit(sublet)}
                style={{marginRight: 10}}
              >Edit</Button>
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  if (window.confirm('Remove this item?')) {
                    props.onRemove(sublet);
                  }
                }}
              >Delete</Button>
            </div>
          </CardActions>
        </Grid>
        <Grid item xs={4}>
          <ClaimAttachments
            title="Attachments"
            attachments={attachments}
            readOnly
          />
        </Grid>
      </Grid>
    </Card>
  );
}

function LineItems(props: {
  labors: ClaimLaborItem[];
  parts: ClaimPartItem[];
  sublets: ClaimSubletItem[];
  onEditLabor: (labor: ClaimLaborItem) => void;
  onEditPart: (labor: ClaimPartItem) => void;
  onEditSublet: (labor: ClaimSubletItem) => void;
  onRemoveLabor: (labor: ClaimLaborItem) => void;
  onRemovePart: (part: ClaimPartItem) => void;
  onRemoveSublet: (sublet: ClaimSubletItem) => void;
}) {
  const classes = useStyles();

  return (
    <div style={{paddingTop: 10, paddingBottom: 10}}>
      {props.labors.map((labor, i) => {
        return (
          <LaborLineItem
            key={`labor-item-${i}-${labor.updated_at}`}
            counter={i+1}
            labor={labor}
            onEdit={props.onEditLabor}
            onRemove={props.onRemoveLabor}
          />
        );
      })}

      {props.parts.map((part, i) => {
        return (
          <PartLineItem
            key={`part-item-${i}-${part.updated_at}`}
            counter={i+1+props.labors.length}
            part={part}
            onEdit={props.onEditPart}
            onRemove={props.onRemovePart}
          />
        );
      })}

      {props.sublets.map((sublet, i) => {
        return (
          <SubletLineItem
            key={`sublet-item-${i}-${sublet.updated_at}`}
            counter={i+1+props.labors.length+props.parts.length}
            sublet={sublet}
            onEdit={props.onEditSublet}
            onRemove={props.onRemoveSublet}
          />
        );
      })}
    </div>
  );
}


interface FindCustomerFormValues {
  search: string;
}

interface FoundCustomer {
  customer: string;
  customerData: Customer;
  customers: Customer[];
}

function FindCustomerForm(props: {
  dealer?: Dealer|null;
  onSave: (found: FoundCustomer) => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [initiated, setInitiated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [customers, setCustomers] = useState([] as Customer[]);
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [customersMeta, setCustomersMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0
  });

  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();

  const customersApi = useRef(new BaseAPI);
  const loadCustomers = async (page?: number, search?: string) => {
    const api = customersApi.current;
    let url = `warranty-customers/`;
    let kwargs: any = {};
    if (page) kwargs.page = page;
    if (search) kwargs.search = search;
    url = `${url}?${new URLSearchParams(kwargs).toString()}`;
    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        if (page && (page > customersMeta.page)) {
          const results = data.results as Customer[];

          setCustomers(Object.assign([], customers).concat(results));
        }
        else {
          setCustomers(data.results as Customer[]);
        }

        setCustomersMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next
        });
      }
    } catch (error) {
      console.error(error);
    }

  };


  const onSubmit = async (values: FindCustomerFormValues) => {
    setIsLoading(true);
    setShowErrorSnackBar(false);
    setInitiated(true);
    const api = new BaseAPI();

    await loadCustomers(1, values.search);
    setIsLoading(false);
  }

  const onValidate = (values: FindCustomerFormValues) => {
    const errors: any = {}

    if (!values.search) errors.search = "Required";

    return errors;
  }

  return (
    <div>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        search: '',
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
        <form onSubmit={handleSubmit} className={classes.root} style={{margin: 12}}>
          <Grid container>
            <Grid container spacing={3}>
              <Field name="search">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Search'}
                        placeholder="Enter keywords to search (HIN, customer name, email address, etc)..."
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        focused
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            {(!pristine && (Object.keys(errors as any).length > 0)) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please enter some keywords to search!</Alert>
              </div>
            </Grid>}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isLoading}
              >Find</Button>
              {isLoading && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />
    {(!isLoading && !!initiated && customers.length === 0) && <div style={{margin: 12}}>
      <Alert
        severity="error"
      >No warranty registration found.</Alert>
    </div>}
    {(!isLoading && !!initiated && customers.length > 0) && <div style={{margin: 12}}>
    <TableContainer component={Paper}>
      <Table aria-label="warranty registration list">
        <TableHead>
          <TableRow>
            <TableCell>Serial Number / HIN</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Model</TableCell>
            <TableCell align="right">Dealer</TableCell>
            <TableCell align="right">Phone</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Purchase Date</TableCell>
            <TableCell align="right">Warranty Transfer</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {customers.map((customer, i) => {
          return (
            <TableRow key={`warranty-customer-item-${customer.id}`}>
              <TableCell>
                {customer.boat.serial_number}
              </TableCell>
              <TableCell align="right">
                {customer.first_name} {customer.last_name}
              </TableCell>
              <TableCell align="right">
                {customer.boat.model}
              </TableCell>
              <TableCell align="right">
                {customer.dealer ? customer.dealer.customer_name : '-'}
              </TableCell>
                <TableCell align="right">{customer.phone ? customer.phone : '-'}
              </TableCell>
              <TableCell align="right">
                {customer.email ? customer.email : '-'}
              </TableCell>
              <TableCell align="right">
                {customer.boat.purchase_date}
              </TableCell>
              <TableCell align="right">
                {customer.boat.warranty_transfer ? 'Yes' : 'No'}
              </TableCell>
              <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      props.onSave({
                        customers: customers,
                        customerData: customer,
                        customer: customer.id,
                      })
                    }}
                  >Select</Button>
                </TableCell>
            </TableRow>
          );
        })}
        </TableBody>
      </Table>
    </TableContainer>
    </div>}
    </div>
  );
}
