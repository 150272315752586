import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import formatNumber from "format-number";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Grid,
  Paper,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
  IconButton,
} from "@material-ui/core";

import { TransitionProps } from "@material-ui/core/transitions";

import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { BaseAPI } from "../../data/BaseAPI";
import { useProfile } from "../../data/Profile";
import {
  Customer,
  User,
  WarrantyTransferRequest,
  WarrantyTransferRequestAttachment,
} from "../../types";

import localization from "../../utils/localizations";
import WarrantyTransferRequestForm from "../forms/WarrantyTransferRequestForm";
import WarrantyClaimForm from "../forms/WarrantyClaimForm";
import moment from "moment";
import WarrantyClaimCollection from "../collections/WarrantyClaimCollection";
import ClaimAttachments from "../collections/ClaimAttachments";
import CustomerComments from "../collections/CustomerComments";

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
  partNumber: {
    marginRight: 10,
    minWidth: 100,
  },
  alignRight: {
    textAlign: "right",
  },
  bolder: {
    fontWeight: "bold",
  },
  noteContainer: {
    marginBottom: 20,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  },
  appBar: {
    position: "relative",
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WarrantyTransferRequestDetail(props: {
  transfer: WarrantyTransferRequest;
  onUpdated?: (transfer: WarrantyTransferRequest) => void;
  onDeleted?: (transfer: WarrantyTransferRequest) => void;
  onApproved?: (transfer: WarrantyTransferRequest) => void;
  className?: string;
}) {
  const classes = useStyles();
  const [showEditForm, setShowEditForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [showCreateClaimForm, setShowCreateClaimForm] = useState(false);
  const [redirect, setRedirect] = useState("");
  const transfer = props.transfer;

  const [profile, profileLoading, updateProfile, updateProfilePicture] =
    useProfile();

  const deleteTransfer = async (transfer: WarrantyTransferRequest) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.delete(
        `warranty-transfer-requests/${transfer.id}/`,
      );
      if (props.onDeleted) props.onDeleted(transfer);
    } catch (error) {
      console.error(error);
    }
  };

  const approveTransfer = async (transfer: WarrantyTransferRequest) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.post(
        {},
        `warranty-transfer-requests/${transfer.id}/accept_transfer_request/`,
      );
      if (result.success) {
        if (props.onApproved)
          props.onApproved(result.obj as WarrantyTransferRequest);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const rejectTransfer = async (
    transfer: WarrantyTransferRequest,
    reason: string,
  ) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.post(
        { reason: reason },
        `warranty-transfer-requests/${transfer.id}/reject_transfer_request/`,
      );
      transfer.status = "denied";
      transfer.status_reason = reason;
      if (props.onUpdated) props.onUpdated(transfer);
    } catch (error) {
      console.error(error);
    }
  };

  let isAdmin = false;
  if (profile && profile.role === "admin") {
    isAdmin = true;
  }

  let canEdit = false;
  if (profile && profile.role === "admin") {
    canEdit = true;
  }
  let canDelete = canEdit;

  if (
    profile &&
    profile.role === "customer" &&
    transfer.status === "submitted"
  ) {
    profile.dealers.forEach((dealer) => {
      if (dealer.id === transfer.created_by_dealer?.id) {
        canEdit = true;
      }
    });
  }

  if (canEdit && showEditForm) {
    return (
      <div className={`${classes.root} ${props.className}`}>
        <WarrantyTransferRequestForm
          transfer={props.transfer}
          dealerId=""
          onSave={(transfer) => {
            setShowEditForm(false);
            if (props.onUpdated) props.onUpdated(transfer);
          }}
          onCancel={() => {
            setShowEditForm(false);
          }}
        />
      </div>
    );
  }

  if (!props.onDeleted) canDelete = false;
  if (!props.onUpdated) canEdit = false;

  let serialNumber = props.transfer?.original_customer?.boat?.serial_number;
  if (serialNumber && serialNumber.startsWith("US")) {
    serialNumber = serialNumber.substring(2);
  }

  return (
    <div className={`${classes.root} ${props.className}`}>
      <Grid
        container
        spacing={3}
        component={Paper}
        style={{ margin: 0, marginBottom: 12, width: "100%" }}
      >
        <Grid item xs={4}>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="h6">New Customer Information</Typography>
            <Typography variant="body2">
              Name: {props.transfer.first_name} {props.transfer.last_name}{" "}
              <br />
              Email:{" "}
              {props.transfer.email ? (
                <a href={`mailto:${props.transfer.email}`}>
                  {props.transfer.email}
                </a>
              ) : (
                "-"
              )}{" "}
              <br />
              Phone:{" "}
              {props.transfer.phone ? (
                <a href={`tel:${props.transfer.phone}`}>
                  {props.transfer.phone}
                </a>
              ) : (
                "-"
              )}{" "}
              <br />
              {props.transfer.servicing_dealer ? (
                <span>
                  Servicing Dealer <br />
                </span>
              ) : (
                ""
              )}
              {props.transfer.no_warranty ? (
                <span>
                  No Warranty / See Notes <br />
                </span>
              ) : (
                ""
              )}
              {props.transfer.rental_fleet ? <Chip label="Rental Fleet" /> : ""}
            </Typography>
          </div>

          <div className={classes.shippingAddressContainer}>
            <Typography variant="body1">Address</Typography>
            <Typography variant="body2">
              {props.transfer.address_line_1}
            </Typography>
            <Typography variant="body2">
              {props.transfer.city} {props.transfer.state} {props.transfer.zip}
            </Typography>
            <Typography variant="body2">{props.transfer.country}</Typography>
          </div>

          <div className={classes.shippingAddressContainer}>
            <Typography variant="body1">Notes</Typography>
            <Typography variant="body2">
              {props.transfer.comments ? props.transfer.comments : "-"}
            </Typography>
          </div>

          <div className={classes.shippingAddressContainer}>
            <Typography variant="body1">Status</Typography>
            <Typography variant="body2">
              {props.transfer.status
                ? props.transfer.status.toUpperCase()
                : "-"}
            </Typography>
            <Typography variant="body2">
              {props.transfer.status_reason ? props.transfer.status_reason : ""}
            </Typography>
            {transfer.approved_customer && (
              <div>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<OpenInNewIcon />}
                  target="_blank"
                  href={`/warranty/customers/${transfer.approved_customer.id}/`}
                  title="view warranty registration"
                  style={{ marginRight: 6 }}
                >
                  View Approved Warranty Registration
                </Button>
              </div>
            )}
          </div>

          <div className={classes.shippingAddressContainer}>
            <Typography variant="h6">Previous Customer Information</Typography>
            <Typography variant="body2">
              Name: {props.transfer.original_customer.first_name}{" "}
              {props.transfer.original_customer.last_name} <br />
              Email:{" "}
              {props.transfer.original_customer.email ? (
                <a href={`mailto:${props.transfer.original_customer.email}`}>
                  {props.transfer.original_customer.email}
                </a>
              ) : (
                "-"
              )}{" "}
              <br />
              Phone:{" "}
              {props.transfer.original_customer.phone ? (
                <a href={`tel:${props.transfer.original_customer.phone}`}>
                  {props.transfer.original_customer.phone}
                </a>
              ) : (
                "-"
              )}{" "}
              <br />
              {props.transfer.original_customer.servicing_dealer ? (
                <span>
                  Servicing Dealer <br />
                </span>
              ) : (
                ""
              )}
              {props.transfer.original_customer.no_warranty ? (
                <span>
                  No Warranty / See Notes <br />
                </span>
              ) : (
                ""
              )}
              {props.transfer.original_customer.rental_fleet ? (
                <Chip label="Rental Fleet" />
              ) : (
                ""
              )}
            </Typography>
          </div>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="body1">Address</Typography>
            <Typography variant="body2">
              {props.transfer.original_customer.address_line_1}
            </Typography>
            <Typography variant="body2">
              {props.transfer.original_customer.city}{" "}
              {props.transfer.original_customer.state}{" "}
              {props.transfer.original_customer.zip}
            </Typography>
            <Typography variant="body2">
              {props.transfer.original_customer.country}
            </Typography>
          </div>

          {profile.role === "admin" && (
            <div className={classes.shippingAddressContainer}>
              {!!props.transfer.created_by && (
                <Typography variant="body2">
                  Created By: {props.transfer.created_by.first_name}{" "}
                  {props.transfer.created_by.last_name} on{" "}
                  {moment(props.transfer.created_at).format(
                    "MMMM Do YYYY hh:mm:ss",
                  )}
                </Typography>
              )}
              {!!props.transfer.updated_by && (
                <Typography variant="body2">
                  Updated By: {props.transfer.updated_by.first_name}{" "}
                  {props.transfer.updated_by.last_name} on{" "}
                  {moment(props.transfer.updated_at).format(
                    "MMMM Do YYYY hh:mm:ss",
                  )}
                </Typography>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={4}>
          {!!props.transfer.original_customer.boat && (
            <div className={classes.noteContainer}>
              <Typography variant="h6">Boat</Typography>
              <Typography variant="body2">
                Model: {props.transfer.original_customer.boat.model} <br />
                Serial / HIN:{" "}
                {props.transfer.original_customer.boat.serial_number} <br />
                Engine #1 Serial:{" "}
                {props.transfer.original_customer.boat.engine_serial} <br />
                {props.transfer.original_customer.boat.engine_serial_2 && (
                  <div>
                    Engine #2 Serial:{" "}
                    {props.transfer.original_customer.boat.engine_serial_2}
                  </div>
                )}
                {props.transfer.original_customer.boat.engine_serial_3 && (
                  <div>
                    Engine #3 Serial:{" "}
                    {props.transfer.original_customer.boat.engine_serial_3}
                  </div>
                )}
                Purchase Date:{" "}
                {moment(
                  props.transfer.original_customer.boat.purchase_date,
                ).format("MMMM Do YYYY")}{" "}
                <br />
                Registration Date:{" "}
                {moment(
                  props.transfer.original_customer.boat.registration_date,
                ).format("MMMM Do YYYY")}
                <br />
                New Registration Date:{" "}
                {moment(props.transfer.registration_date).format(
                  "MMMM Do YYYY",
                )}
                <br />
              </Typography>
            </div>
          )}
        </Grid>

        {!!props.transfer.created_by_dealer &&
          props.transfer.created_by_dealer.customer_number != "0" && (
            <Grid item xs={4}>
              <div className={classes.shippingAddressContainer}>
                <Typography variant="h6">Dealer</Typography>
                <Typography variant="body2">
                  Dealer Name: {props.transfer.created_by_dealer.customer_name}{" "}
                  <br />
                  Dealer #:{" "}
                  {props.transfer.created_by_dealer.customer_number.padStart(
                    5,
                    "0",
                  )}{" "}
                  <br />
                  Email:{" "}
                  {props.transfer.created_by_dealer.email ? (
                    <a
                      href={`mailto:${props.transfer.created_by_dealer.email}`}
                    >
                      {props.transfer.created_by_dealer.email}
                    </a>
                  ) : (
                    "-"
                  )}{" "}
                  <br />
                </Typography>
              </div>
              {!props.transfer.dealer_address && (
                <>
                  {props.transfer.created_by_dealer.addresses.map(
                    (dealerAddress, i) => (
                      <div
                        className={classes.shippingAddressContainer}
                        key={`address-${i}`}
                      >
                        <Typography variant="body1">
                          Address #{i + 1}
                        </Typography>
                        <Typography variant="body2">
                          Email:{" "}
                          {dealerAddress.email ? (
                            <a href={`mailto:${dealerAddress.email}`}>
                              {dealerAddress.email}
                            </a>
                          ) : (
                            "-"
                          )}{" "}
                          <br />
                          Phone:{" "}
                          {dealerAddress.phone ? (
                            <a href={`tel:${dealerAddress.phone}`}>
                              {dealerAddress.phone}
                            </a>
                          ) : (
                            "-"
                          )}{" "}
                          <br />
                        </Typography>
                        <Typography variant="body2">
                          {dealerAddress.address_line_1}
                        </Typography>
                        <Typography variant="body2">
                          {dealerAddress.city} {dealerAddress.state}{" "}
                          {dealerAddress.zip}
                        </Typography>
                        <Typography variant="body2">
                          {dealerAddress.country}
                        </Typography>
                      </div>
                    ),
                  )}
                </>
              )}
              {!!props.transfer.dealer_address && (
                <div className={classes.shippingAddressContainer}>
                  <Typography variant="body1">Address</Typography>
                  <Typography variant="body2">
                    Email:{" "}
                    {props.transfer.dealer_email ? (
                      <a href={`mailto:${props.transfer.dealer_email}`}>
                        {props.transfer.dealer_email}
                      </a>
                    ) : (
                      "-"
                    )}{" "}
                    <br />
                    Phone:{" "}
                    {props.transfer.dealer_phone ? (
                      <a href={`tel:${props.transfer.dealer_phone}`}>
                        {props.transfer.dealer_phone}
                      </a>
                    ) : (
                      "-"
                    )}{" "}
                    <br />
                  </Typography>
                  <Typography variant="body2">
                    {props.transfer.dealer_address}
                  </Typography>
                  <Typography variant="body2">
                    {props.transfer.dealer_city} {props.transfer.dealer_state}{" "}
                    {props.transfer.dealer_zip}
                  </Typography>
                  <Typography variant="body2">
                    {props.transfer.dealer_country}
                  </Typography>
                </div>
              )}
            </Grid>
          )}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.transfer.attachments.length > 0 && (
            <ClaimAttachments
              title="Attachments"
              attachments={props.transfer.attachments}
              readOnly
            />
          )}
        </Grid>
      </Grid>

      <div
        style={{
          marginBottom: 20,
          marginTop: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {transfer.approved_customer && (
          <div>
            <Button
              variant="contained"
              color="default"
              startIcon={<OpenInNewIcon />}
              target="_blank"
              href={`/warranty/customers/${transfer.approved_customer.id}/`}
              title="view warranty registration"
              style={{ marginRight: 6 }}
            >
              View Approved Warranty Registration
            </Button>
          </div>
        )}
        {isAdmin && !transfer.approved_customer && (
          <div>
            <Button
              variant="contained"
              color="primary"
              disabled={transfer.status === "approved"}
              onClick={() => {
                if (window.confirm("Approve this warranty transfer request?")) {
                  approveTransfer(transfer);
                }
              }}
              style={{ marginRight: 10 }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={transfer.status === "denied"}
              onClick={() => {
                if (window.confirm("Deny this warranty transfer request?")) {
                  let reason = window.prompt("Denial reason:");
                  rejectTransfer(transfer, reason ? reason : "");
                }
              }}
              style={{ marginRight: 10 }}
            >
              Deny
            </Button>
          </div>
        )}
        {canEdit && (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowEditForm(true)}
              style={{ marginRight: 10 }}
            >
              Edit
            </Button>

            {canDelete && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (
                    window.confirm("Remove this warranty transfer request?")
                  ) {
                    deleteTransfer(props.transfer);
                  }
                }}
                style={{ marginRight: 10 }}
              >
                Delete
              </Button>
            )}
          </div>
        )}
      </div>

      <hr />

      {!!redirect && <Redirect to={redirect} />}
    </div>
  );
}
