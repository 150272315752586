import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import formatNumber from "format-number";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  ListSubheader,
  LinearProgress,
  ButtonGroup,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  AppBar,
  Slide,
} from "@material-ui/core";

import { TransitionProps } from "@material-ui/core/transitions";
import { Skeleton, Alert } from "@material-ui/lab";
import PreviousIcon from "@material-ui/icons/ArrowBack";
import NextIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import {
  PdfHighlighter,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";

import { PdfLoader } from "./PdfLoader";

import "react-pdf-highlighter/dist/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  container: {
    paddingBottom: 10,
  },
  appBar: {
    position: "relative",
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  closeButton: {
    padding: 4,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 40,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PDFPreviewDialog(props: {
  url: undefined | string;
  title: undefined | string;
  description: undefined | string;
  onClose: () => void;
}) {
  const classes = useStyles();
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);

  return (
    <Dialog
      open={!!props.url}
      fullScreen
      onClose={() => props.onClose()}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.dialogTitle}>
            {props.title}
          </Typography>
          <Typography style={{ marginRight: 20 }}>
            {props.description}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            component="a"
            href={props.url}
            target="_blank"
            aria-label="close"
          >
            <CloudDownloadIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              props.onClose();
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="pdf-preview">
        {!!props.url && (
          <div>
            <PdfLoader
              url={props.url}
              beforeLoad={
                <div className={classes.loadingContainer}>
                  <CircularProgress disableShrink />
                </div>
              }
            >
              {(pdfDocument: any) => (
                <PdfHighlighter
                  pdfDocument={pdfDocument}
                  enableAreaSelection={(event: any) => {
                    return event.altKey;
                  }}
                  onScrollChange={() => {}}
                  scrollRef={(scrollTo: any) => {}}
                  onSelectionFinished={(
                    position: any,
                    content: any,
                    hideTipAndSelection: any,
                    transformSelection: any,
                  ) => {
                    return null;
                  }}
                  highlightTransform={(
                    highlight: any,
                    index: any,
                    setTip: any,
                    hideTip: any,
                    viewportToScaled: any,
                    screenshot: any,
                    isScrolledTo: any,
                  ) => {
                    return <></>;
                  }}
                  highlights={[]}
                />
              )}
            </PdfLoader>
          </div>
        )}
      </div>

      <Snackbar
        key={"mark-as-paid-error-snackbar"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={showErrorSnackbar}
        onClose={() => setShowErrorSnackBar(false)}
        onExited={() => setShowErrorSnackBar(false)}
        message="Error loading your data. Please try again. Contact us if the issue persist."
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.closeButton}
              onClick={() => setShowErrorSnackBar(false)}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </Dialog>
  );
}
