import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
  APIError,
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import {
  Customer,
  Dealer,
  Session,
  Boat,
  Order,
  ClaimAttachment,
  WarrantyTransferRequest,
  WarrantyTransferRequestAttachment,
} from '../../types';

import {
  isSamePhoneNumber,
  isSameEmail,
  isSameAddress,
  isSimilarAddress
} from '../../utils/formUtils';

import DateTimeView from '../singles/DateTimeView';

import CountrySelect from './fields/CountrySelect';
import StateSelect from './fields/StateSelect';
import BoatSelect from './fields/BoatSelect';
import ClaimAttachments from '../collections/ClaimAttachments';
import HinField from './fields/HinField';

const useStyles = makeStyles({
  root: {
    padding: 10,
    borderRadius: 4
  },
  form: {

  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  closeButton: {
    padding: 4,
  },
  bgWhite: {
    backgroundColor: '#ffffff',
  }
});


interface FormValues {
  original_customer: string;
  created_by_dealer: string;
  registration_date: string;
  first_name: string;
  last_name: string;
  serial_number: string;
  phone: string;
  email: string;
  address_line_1: string;
  city: string;
  country: string;
  state: string;
  zip: string;
  dealer_name: string;
  dealer_phone: string;
  dealer_email: string;
  dealer_address: string;
  dealer_city: string;
  dealer_country: string;
  dealer_state: string;
  dealer_zip: string;
  rental_fleet: boolean;
  servicing_dealer: boolean;
  no_warranty: boolean;
  comments: string;
}

export default function WarrantyTransferRequestForm(props: {
  transfer: WarrantyTransferRequest|null|undefined;
  dealerId: string;
  customerId?: string;
  serialNumber?: string;
  onSave: (transfer: WarrantyTransferRequest) => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [showSerialNumberPicker, setShowSerialNumberPicker] = useState(false);
  const [currentForm, setCurrentForm] = useState<any>(undefined);
  const [currentSerialNumber, setCurrentSerialNumber] = useState<string>('');
  const [attachments, setAttachments] = useState<WarrantyTransferRequestAttachment[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);

  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [selectedDealer, setSelectedDealer] = useState(null as Dealer|null);
  const [allDealers, setALlDealers] = useState(((window as any)._cached && (window as any)._cached['all_dealers'] ? (window as any)._cached['all_dealers']: []) as Dealer[]);

  const loadAllDealers = async () => {

    const api = new BaseAPI();
    try {
      const data = await api.get('all-dealers/');
      if (data instanceof Array) {
        setALlDealers(data as Dealer[]);

        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached['all_dealers']) (window as any)._cached['all_dealers'] = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(allDealers.length === 0) loadAllDealers();
  }, []);

  useEffect(() => {
    if (props.transfer?.created_by_dealer?.id) {
      setSelectedDealer(props.transfer.created_by_dealer);
    }
  }, [props.transfer?.created_by_dealer?.id]);

  useEffect(() => {
    if (props.transfer?.attachments) {
      setAttachments(props.transfer.attachments);
    }
  }, [props.transfer?.attachments?.length]);

  const onSubmit = async (values: FormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();

    let success = false;
    let id: number|undefined = undefined;
    let transfer: WarrantyTransferRequest|undefined = undefined;

    if (!props.transfer) {
      try {
        const [result, response, error] = await api.post({
          original_customer: values.original_customer,
          created_by_dealer: values.created_by_dealer,
          registration_date: values.registration_date,
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          address_line_1: values.address_line_1,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
          dealer_name: values.dealer_name,
          dealer_phone: values.dealer_phone,
          dealer_email: values.dealer_email,
          dealer_address: values.dealer_address,
          dealer_city: values.dealer_city,
          dealer_state: values.dealer_state,
          dealer_country: values.dealer_country,
          dealer_zip: values.dealer_zip,
          rental_fleet: values.rental_fleet,
          servicing_dealer: values.servicing_dealer,
          no_warranty: values.no_warranty,
          comments: values.comments,
          attachment_ids: attachments.map(attachment => attachment.id),
          removed_attachment_ids: removedAttachments,
        }, 'warranty-transfer-requests/create_transfer_request/');
        id = result.id;
        transfer = result as WarrantyTransferRequest;
        console.log('result', result);

      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
      setIsSaving(false);

      if (id && transfer) {
        props.onSave(transfer);
      }
    }
    else {
      try {
        const [result, response, error] = await api.post({
          id: props.transfer.id,
          original_customer: values.original_customer,
          created_by_dealer: values.created_by_dealer,
          registration_date: values.registration_date,
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          address_line_1: values.address_line_1,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
          dealer_name: values.dealer_name,
          dealer_phone: values.dealer_phone,
          dealer_email: values.dealer_email,
          dealer_address: values.dealer_address,
          dealer_city: values.dealer_city,
          dealer_state: values.dealer_state,
          dealer_country: values.dealer_country,
          dealer_zip: values.dealer_zip,
          rental_fleet: values.rental_fleet,
          servicing_dealer: values.servicing_dealer,
          no_warranty: values.no_warranty,
          comments: values.comments,
          attachment_ids: attachments.map(attachment => attachment.id),
          removed_attachment_ids: removedAttachments,
        }, `warranty-transfer-requests/${props.transfer.id}/edit_transfer_request/`);
        id = result.id;
        transfer = result as WarrantyTransferRequest;
        console.log('result', result);
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
      setIsSaving(false);

      if (id && transfer) {
        props.onSave(transfer);
      }
    }

    setIsSaving(false);
  }

  const onValidate = (values: FormValues) => {
    const errors: any = {}

    if ((attachments.length === 0) && (!props.transfer)) errors[FORM_ERROR] = "Please upload a warranty registration attachment!";

    if (!values.created_by_dealer) errors.created_by_dealer = "Required";
    if (!values.registration_date) errors.registration_date = "Required";

    if (!values.serial_number) errors.serial_number = "Required";
    if (!values.first_name) errors.first_name = "Required";
    if (!values.last_name) errors.last_name = "Required";
    if (!values.email) errors.email = "Required";
    if (!values.phone) errors.phone = "Required";
    if (!values.address_line_1) errors.address_line_1 = "Required";
    if (!values.city) errors.city = "Required";
    if (!values.state) errors.state = "Required";
    if (!values.zip) errors.zip = "Required";
    if (!values.country) errors.country = "Required";

    if (profile.role !== 'admin') {
      if (values.phone && !errors.phone && isSamePhoneNumber(values.phone, values.dealer_phone)) {
        errors.phone = "Please use the customer's phone number instead of dealer's phone number";
      }
      if (values.email && !errors.email && isSameEmail(values.email, values.dealer_email)) {
        errors.email = "Please use the customer's email address instead of dealer's email address";
      }
      if (
        values.address_line_1 &&
        !errors.address_line_1 &&
        isSameAddress(
          `${values.address_line_1} ${values.city ? values.city : ''} ${values.state ? values.state : ''} ${values.zip ? values.zip : ''} ${values.country ? values.country : ''}`,
          `${values.dealer_address} ${values.dealer_city ? values.dealer_city : ''} ${values.dealer_state ? values.dealer_state : ''} ${values.dealer_zip ? values.dealer_zip : ''} ${values.dealer_country ? values.dealer_country : ''}`
        )) {
        errors.address_line_1 = "Please use the customer's address instead of dealer's address";
      }
    }

    if (!values.dealer_name) errors.dealer_name = "Required";
    if (!values.dealer_email) errors.dealer_email = "Required";
    if (!values.dealer_phone) errors.dealer_phone = "Required";
    if (!values.dealer_address) errors.dealer_address = "Required";
    if (!values.dealer_city) errors.dealer_city = "Required";
    if (!values.dealer_state) errors.dealer_state = "Required";
    if (!values.dealer_zip) errors.dealer_zip = "Required";
    if (!values.dealer_country) errors.dealer_country = "Required";

    return errors;
  }


  const transfer = props.transfer;

  return (
    <div className={`${classes.root} ${classes.bgWhite} ${props.className ? props.className : ''}`}>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        registration_date: transfer?.original_customer?.boat ? transfer.original_customer.boat.registration_date : moment().format('YYYY-MM-DD'),
        created_by_dealer: (transfer && transfer.created_by_dealer) ? transfer.created_by_dealer.id : props.dealerId,
        original_customer: (transfer && transfer.original_customer) ? transfer.original_customer.id : props.customerId,
        serial_number: (transfer && transfer.original_customer) ? transfer.original_customer.boat.serial_number : props.serialNumber,
        first_name: transfer ? transfer.first_name : '',
        last_name: transfer ? transfer.last_name : '',
        email: transfer ? transfer.email : '',
        phone: transfer ? transfer.phone : '',
        address_line_1: transfer ? transfer.address_line_1 : '',
        city: transfer ? transfer.city : '',
        state: transfer ? transfer.state : '',
        zip: transfer ? transfer.zip : '',
        country: transfer ? transfer.country : 'United States',
        dealer_name: transfer ? transfer.dealer_name : '',
        dealer_email: transfer ? transfer.dealer_email : '',
        dealer_phone: transfer ? transfer.dealer_phone : '',
        dealer_address: transfer ? transfer.dealer_address : '',
        dealer_city: transfer ? transfer.dealer_city : '',
        dealer_state: transfer ? transfer.dealer_state : '',
        dealer_zip: transfer ? transfer.dealer_zip : '',
        dealer_country: transfer ? transfer.dealer_country : 'United States',
        rental_fleet: transfer ? transfer.rental_fleet : false,
        servicing_dealer: transfer ? transfer.servicing_dealer : false,
        no_warranty: transfer ? transfer.no_warranty : false,
        comments: transfer ? transfer.comments : '',
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container>

            <Grid container spacing={3}>
              <Field name="created_by_dealer">
                {props => (
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Dealer</InputLabel>
                      <Select
                        id={props.input.name}
                        label={'Dealer'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={(event) => {
                          props.input.onChange(event);
                          allDealers.filter((dealer) => {
                            if (dealer.id == event.target.value) {
                              setSelectedDealer(dealer);
                              if (dealer.addresses.length > 0) {
                                const address = dealer.addresses[0];
                                form.change('dealer_name', dealer.customer_name);
                                if (address.email) {
                                  form.change('dealer_email', address.email);
                                }
                                else {
                                  form.change('dealer_email', dealer.email);
                                }

                                form.change('dealer_phone', address.phone);
                                form.change('dealer_address', address.address_line_1);
                                form.change('dealer_city', address.city);
                                form.change('dealer_country', address.country);
                                form.change('dealer_state', address.state);
                                form.change('dealer_zip', address.zip);
                              }
                            }
                          })
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      >
                        <ListSubheader>Active Dealers</ListSubheader>
                        {allDealers.filter(dealer => dealer.active).map((dealer, i) => (
                          <MenuItem key={`dealer-menu-item-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                        ))}
                        <ListSubheader>Inactive Dealers</ListSubheader>
                        {allDealers.filter(dealer => !dealer.active).map((dealer, i) => (
                          <MenuItem key={`dealer-menu-item-inactive-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                        ))}
                      </Select>
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="serial_number">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Serial Number / HIN'}
                        placeholder="LYGAB123C456"
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        onClick={() => {
                          setCurrentForm(form);
                          setCurrentSerialNumber(props.input.value);
                          setShowSerialNumberPicker(true);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            <Grid container spacing={3}>

              <Field name="registration_date">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Registration Date'}
                        variant="outlined"
                        type="date"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            <Grid container spacing={3} style={{marginBottom: 12}}>


              <Field name="rental_fleet">
                {props => (
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                                  checked={props.input.value}
                                  onChange={(event, checked) => {
                                    props.input.onChange(event);
                                  }}
                                  name={props.input.name}
                                />}
                      label="Rental Fleet"
                    />
                  </Grid>
                )}
              </Field>

              <Field name="servicing_dealer">
                {props => (
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                                  checked={props.input.value}
                                  onChange={(event, checked) => {
                                    props.input.onChange(event);
                                  }}
                                  name={props.input.name}
                                />}
                      label="Servicing Dealer"
                    />
                  </Grid>
                )}
              </Field>

              <Field name="no_warranty">
                {props => (
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                                  checked={props.input.value}
                                  onChange={(event, checked) => {
                                    props.input.onChange(event);
                                  }}
                                  name={props.input.name}
                                />}
                      label="No Warranty / See Notes"
                    />
                  </Grid>
                )}
              </Field>
            </Grid>


            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant='h5' style={{paddingBottom: 12}}>Customer Information</Typography>
              </Grid>

              <Grid container spacing={3}>
                <Field name="first_name">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'First Name'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={((props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined) || "If there are two names, please enter both of them (e.g. Jim & Jan)"}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="last_name">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Last Name'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="email">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Email'}
                          variant="outlined"
                          type="email"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="phone">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Phone'}
                          variant="outlined"
                          type="tel"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>

              <Field name="address_line_1">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Address'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                      {!(props.meta.error || props.meta.submitError) &&
                        values.address_line_1 &&
                          (isSimilarAddress(
                          `${values.address_line_1} ${values.city ? values.city : ''} ${values.state ? values.state : ''} ${values.zip ? values.zip : ''} ${values.country ? values.country : ''}`,
                          `${values.dealer_address} ${values.dealer_city ? values.dealer_city : ''} ${values.dealer_state ? values.dealer_state : ''} ${values.dealer_zip ? values.dealer_zip : ''} ${values.dealer_country ? values.dealer_country : ''}`
                        )) &&
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled" id="address_line_1-helper-text">This address is very similar with the dealer's address. Please make sure to use the customer's address instead of dealer's address.</p>}
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Grid container spacing={3}>
                <Field name="city">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'City'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="country">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>Country</InputLabel>
                        <CountrySelect
                          id={props.input.name}
                          label={'Country'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                        }
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="state">
                  {props => (
                    <Grid item xs={3}>
                      {values.country === 'United States' &&
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>State</InputLabel>
                        <StateSelect
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                        }
                      </FormControl>}
                      {values.country !== 'United States' &&
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                      }
                    </Grid>
                  )}
                </Field>

                <Field name="zip">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Zip / Postal Code'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>


                <Field name="comments">
                  {props => (
                    <Grid item xs={12}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Note'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                          multiline
                          rows={4}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant='h5' style={{paddingBottom: 12}}>Dealer Information</Typography>
              </Grid>
              <Grid item xs={12}>
                {(!!selectedDealer && selectedDealer.addresses.length > 0) &&
                <div key={`customer-address-${values.created_by_dealer}`} style={{marginTop: 10, marginBottom: 40}}>
                  <div style={{display: 'flex'}}>
                {selectedDealer.addresses.map((address, i) => (
                  <Card key={`address-selector-${i}`} style={{marginRight: 20}}>
                    <CardContent>
                      <Typography variant="body2">
                        <span>{address.address_line_1}</span><br/>
                        <span>{address.city}</span> <span>{address.state}</span> <span>{address.zip}</span><br/>
                        <span>{address.country}</span>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={() => {
                        form.change('dealer_name', selectedDealer.customer_name);
                        if (address.email) {
                          form.change('dealer_email', address.email);
                        }
                        else {
                          form.change('dealer_email', selectedDealer.email);
                        }

                        form.change('dealer_phone', address.phone);
                        form.change('dealer_address', address.address_line_1);
                        form.change('dealer_city', address.city);
                        form.change('dealer_country', address.country);
                        form.change('dealer_state', address.state);
                        form.change('dealer_zip', address.zip);
                      }}>Use</Button>
                    </CardActions>
                  </Card>
                ))}
                </div>
                </div>}

              </Grid>
              <Grid container spacing={3}>
                <Field name="dealer_name">
                  {props => (
                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Dealer Name'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_email">
                  {props => (
                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Email'}
                          variant="outlined"
                          type="email"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
                <Field name="dealer_phone">
                  {props => (
                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Phone'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 20)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>

              <Field name="dealer_address">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Address'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Grid container spacing={3}>
                <Field name="dealer_city">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'City'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_country">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>Country</InputLabel>
                        <CountrySelect
                          id={props.input.name}
                          label={'Country'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                        }
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_state">
                  {props => (
                    <Grid item xs={3}>
                      {values.dealer_country === 'United States' &&
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>State</InputLabel>
                        <StateSelect
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                        }
                      </FormControl>}
                      {values.dealer_country !== 'United States' &&
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                      }
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_zip">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Zip / Postal Code'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>
            </Grid>

            {((errors as any)[FORM_ERROR]) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">{(errors as any)[FORM_ERROR]}</Alert>
              </div>
            </Grid>}

            <Grid item xs={12} style={{marginTop: 12, marginBottom: 12}}>
              <ClaimAttachments
                title="Attachments"
                baseUrl="warranty-transfer-requests-attachments/"
                attachments={attachments}
                onAdded={(attachment) => {
                  const newAttachments = Object.assign([], attachments);
                  newAttachments.push(attachment);
                  setAttachments(newAttachments);
                }}
                onRemoved={(attachment) => {
                  const newRemovedAttachments = Object.assign([], removedAttachments);
                  newRemovedAttachments.push(attachment.id);
                  setRemovedAttachments(newRemovedAttachments);
                }}
              />
            </Grid>

            {(!pristine && (Object.keys(errors as any).length > 0)) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
              </div>
            </Grid>}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isSaving}
              >Save</Button>
              {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />

    <Dialog
      open={showSerialNumberPicker}
      maxWidth='md'
      fullWidth
      onClose={() => {
        setShowSerialNumberPicker(false);
      }}
    >
      <DialogTitle id="form-dialog-title">Find Boats</DialogTitle>
      <FindBoatsBySerialNumberForm
        serialNumber={currentSerialNumber}
        isAdminRole={profile.role === 'admin'}
        onSave={(found) => {
          if (currentForm) {
            currentForm.change('serial_number', found.serial_number);
            currentForm.change('original_customer', found.customer_id);
            if (found.model) currentForm.change('model', found.model);
            if (found.engine_serial) currentForm.change('engine_serial', found.engine_serial);
            if (found.engine_serial_2) currentForm.change('engine_serial_2', found.engine_serial_2);
            if (found.engine_serial_3) currentForm.change('engine_serial_3', found.engine_serial_3);
          }
          setShowSerialNumberPicker(false);
        }}
        onCancel={() => {
          setShowSerialNumberPicker(false);
        }}
      />
    </Dialog>
    </div>
  );
}


function validateHin(hin: string) {
  hin = hin.trim().toUpperCase();
  if (!hin.match(/[L][Y][G][A-Z][A-Z][0-9][0-9][0-9][A-Z][0-9][0-9][0-9]/g)) return false;
  return true;
}

interface FindBoatsBySerialNumberFormValues {
  serial_number: string;
}

interface FoundBoat {
  customer_id: string;
  model: string;
  serial_number: string;
  engine_serial: string;
  engine_serial_2: string;
  engine_serial_3: string;
  is_registered: boolean;
  is_stock: boolean;
}

function FindBoatsBySerialNumberForm(props: {
  serialNumber: string;
  customerId?: string;
  isAdminRole: boolean;
  onSave: (found: FoundBoat) => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [initiated, setInitiated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [serialNumber, setSerialNumber] = useState('');

  const findBoats = async (serialNumber: string) => {
    const api = new BaseAPI();
    try {
      const data = await api.get(`warranty-customers/?serial_number=${serialNumber}`);
      if ((data as any).results instanceof Array) {
        let customers = (data as any).results as Customer[];
        setCustomers(customers);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (values: FindBoatsBySerialNumberFormValues) => {
    setIsLoading(true);
    setShowErrorSnackBar(false);
    setInitiated(true);
    setSerialNumber(values.serial_number ? values.serial_number.toUpperCase() : '');

    // props.onSave(values.serial_number);
    await findBoats(values.serial_number ? values.serial_number.toUpperCase() : '');
    setIsLoading(false);
  }

  const onValidate = (values: FindBoatsBySerialNumberFormValues) => {
    const errors: any = {}

    if (!values.serial_number) errors.serial_number = "Required";
    if (values.serial_number && values.serial_number.includes('_')) errors.serial_number = "Required";

    if (values.serial_number && !validateHin(values.serial_number)) {
      errors.serial_number = 'HIN should always start with LYG, two letters, 3 numbers, 1 letter and 3 numbers. Example: LYGAB123C456'
    }

    return errors;
  }

  return (
    <div>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        serial_number: props.serialNumber,
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
        <form onSubmit={handleSubmit} className={classes.root} style={{margin: 12}}>
          <Grid container>
            <Grid container spacing={3}>
              <Field name="serial_number">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <HinField
                        id={props.input.name}
                        label={'Serial Number / HIN'}
                        placeholder="LYGAB123C456"
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            {(!pristine && (Object.keys(errors as any).length > 0)) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
              </div>
            </Grid>}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isLoading}
              >Find</Button>
              {isLoading && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />
    {(!isLoading && !!initiated && customers.length === 0) && <div style={{margin: 12}}>
      <Alert
        severity="error"
      >No existing registration found with serial number / HIN {serialNumber}.</Alert>
    </div>}
    {customers.filter(customer => customer.stock).map(customer => {
      return (
        <Alert
          severity="error"
          action={
            <Button
              variant="contained"
              color="secondary"
              size="small"

              target="_blank"
              href={`/warranty/customers/${customer.id}/`}
            >
              View stock registration
            </Button>
          }
        >Found a stock registration for {serialNumber} ({customer.boat.model} - {customer.dealer?.customer_name}).</Alert>
      );
    })}
    {(!isLoading && !!initiated && customers.length > 0) && <div style={{margin: 12}}>
    <TableContainer component={Paper}>
      <Table aria-label="orders list">
        <TableHead>
          <TableRow>
            <TableCell align="right">Serial Number</TableCell>
            <TableCell align="right">Boat Model</TableCell>
            <TableCell align="right">Warranty Registration</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((customer) => {
            return (
              <TableRow key={`order-row-${customer.id}`}>
                <TableCell component="th" scope="row">
                  {customer.boat.serial_number}
                </TableCell>
                <TableCell align="right">{customer.boat.model}</TableCell>
                <TableCell align="right">
                  {!customer && "No warranty registration found" }
                  {(customer && !customer.stock) && <div>
                    <div>{customer.first_name} {customer.last_name}</div>
                    <div>{customer.boat.registration_date && <DateTimeView dateOnly value={customer.boat.registration_date} chip size="small" />}</div>
                  </div>}
                  {(customer && customer.stock) && <div>
                    <div>Stock: {customer.dealer.customer_name}</div>
                    <div>{customer.boat.registration_date && <DateTimeView dateOnly value={customer.boat.registration_date} chip size="small" />}</div>
                  </div>}
                </TableCell>
                <TableCell align="right">
                  {customer &&
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<OpenInNewIcon />}
                      target="_blank"
                      href={`/warranty/customers/${customer.id}/`}
                      title="view warranty registration"
                      style={{marginRight: 6}}
                    >View</Button>
                  }
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      let data = {
                        customer_id: customer.id,
                        serial_number: customer.boat.serial_number,
                        model: customer.boat.model,
                        engine_serial: "",
                        engine_serial_2: "",
                        engine_serial_3: "",
                        is_registered: false,
                        is_stock: false,
                      };
                      if (customer.boat.engine_serial) (data as any).engine_serial = customer.boat.engine_serial;
                      if (customer.boat.engine_serial_2) (data as any).engine_serial_2 = customer.boat.engine_serial_2;
                      if (customer.boat.engine_serial_3) (data as any).engine_serial_3 = customer.boat.engine_serial_3;
                      props.onSave(data)
                    }}
                  >Select</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    </div>}
    </div>
  );
}
