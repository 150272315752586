import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DownloadIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import FilePicker from "../forms/fields/FilePicker";
import { ClaimAttachment } from "../../types";
import { APIError, BaseAPI } from "../../data/BaseAPI";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

interface SelectedFile {
  file: File;
  key: string;
}

function canPreview(filename: string) {
  if (!filename.match(/\.(jpg|jpeg|png|gif|pdf)$/i)) return false;
  return true;
}

export default function ClaimAttachments(props: {
  title?: string;
  attachments: ClaimAttachment[];
  readOnly?: boolean;
  baseUrl?: string;
  onPreview?: (attachment: ClaimAttachment) => void;
  onAdded?: (attachment: ClaimAttachment) => void;
  onRemoved?: (attachment: ClaimAttachment) => void;
  style?: React.CSSProperties | undefined;
}) {
  const classes = useStyles();
  const [files, setFiles] = useState<SelectedFile[]>([]);
  const [deletedAttachments, setDeletedAttachments] = useState<string[]>([]);
  const [uploadedAttachments, setUploadedAttachments] = useState<
    ClaimAttachment[]
  >([]);

  const baseUrl = props.baseUrl ? props.baseUrl : "warranty-claim-attachments/";

  const deleteAttachment = async (attachment: ClaimAttachment) => {
    const newDeletedAttachments = Object.assign([], deletedAttachments);
    newDeletedAttachments.push(attachment.id);
    setDeletedAttachments(newDeletedAttachments);

    if (props.onRemoved) props.onRemoved(attachment);

    if (
      !attachment.claim &&
      !attachment.labor &&
      !attachment.part &&
      !attachment.sublet
    ) {
      const api = new BaseAPI();
      try {
        const [result, request] = await api.delete(
          `${baseUrl}${attachment.id}/`,
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  if (props.readOnly && props.attachments.length === 0) return <></>;

  const filteredAttachments = props.attachments.filter(
    (attachment) =>
      !deletedAttachments.find((deletedId) => attachment.id === deletedId),
  );

  return (
    <Paper className={classes.root} style={props.style}>
      <List
        dense
        component="nav"
        aria-label={props.title}
        subheader={
          props.title ? <ListSubheader>{props.title}</ListSubheader> : <></>
        }
      >
        {filteredAttachments.map((attachment, i) => (
          <ListItem
            key={`attachment-download-${i}`}
            button
            component="a"
            href={`${attachment.url}download/`}
            onClick={(e) => {
              if (props.onPreview && canPreview(attachment.name)) {
                e.preventDefault();
                props.onPreview(attachment);
              }
            }}
          >
            <ListItemIcon>
              <AttachmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={attachment.name}
              secondary={attachment.size_human}
            />
            {!props.readOnly && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    if (window.confirm(`Delete ${attachment.name}?`)) {
                      deleteAttachment(attachment);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
            {props.readOnly && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="download"
                  href={`${attachment.url}download/`}
                >
                  <DownloadIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
        {filteredAttachments.length === 0 && (
          <ListItem>
            <ListItemText primary="No file found" />
          </ListItem>
        )}
        {files.map((f) => (
          <ClaimAttachmentUploadItem
            key={`file-upload-${f.key}`}
            baseUrl={baseUrl}
            file={f.file}
            onCanceled={(file) => {
              console.log("canceled", file);
              setFiles(files.filter((f) => f.file !== file));
            }}
            onCompleted={(attachment, file) => {
              setFiles(files.filter((f) => f.file !== file));

              const newUploadedAttachments = Object.assign(
                [],
                uploadedAttachments,
              );
              newUploadedAttachments.push(attachment);
              setUploadedAttachments(newUploadedAttachments);

              if (props.onAdded) props.onAdded(attachment);
            }}
          />
        ))}
      </List>
      {!props.readOnly && (
        <>
          <Divider />
          <List component="nav" aria-label="operations">
            <FilePicker
              label={
                <ListItem button>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add a new file..." />
                </ListItem>
              }
              accept="image/png, image/gif, image/jpeg, application/pdf"
              multiple
              onChange={(selectedFiles) => {
                const newFiles: SelectedFile[] = Object.assign([], files);
                selectedFiles.forEach((file) => {
                  newFiles.push({
                    key: moment().format(),
                    file: file,
                  });
                });
                setFiles(newFiles);
              }}
            />
          </List>
        </>
      )}
    </Paper>
  );
}

function ClaimAttachmentUploadItem(props: {
  baseUrl: string;
  file: File;
  onCompleted: (attachment: ClaimAttachment, file: File) => void;
  onCanceled?: (file: File) => void;
}) {
  const [selectedFileXHR, setSelectedFileXHR] = useState<
    XMLHttpRequest | undefined
  >(undefined);
  const [selectedFileProgress, setSelectedFileProgress] = useState({
    loaded: 0,
    total: 0,
  });
  const [error, setError] = useState<APIError | undefined>(undefined);

  const uploadFile = async (f: File) => {
    setError(undefined);
    const api = new BaseAPI();
    const data = new FormData();
    data.append("file", f);
    data.append("name", f.name);

    try {
      const [result, request] = await api.upload(
        data,
        `${props.baseUrl}upload/`,
        (xhr: XMLHttpRequest) => {
          setSelectedFileXHR(xhr);
        },
        (loaded: number, total: number) => {
          setSelectedFileProgress({
            loaded: loaded,
            total: total,
          });
        },
      );
      return result as ClaimAttachment;
    } catch (error) {
      console.error(error);
      setError(error as APIError);
    }
    return undefined;
  };

  const createAttachment = async (f: File) => {
    const attachment = await uploadFile(f);
    if (attachment) props.onCompleted(attachment, f);
  };

  useEffect(() => {
    createAttachment(props.file);
  }, []);

  let progressText = "0%";
  let total = 0;
  if (selectedFileProgress.total) {
    total = (selectedFileProgress.loaded / selectedFileProgress.total) * 100;
    progressText = `${total.toFixed(0)}%`;
  }

  if (error) {
    progressText = `Error: ${error.message}`;
  }

  return (
    <ListItem>
      {!error && (
        <ListItemIcon>
          {total === 0 && <CircularProgress />}
          {total > 0 && (
            <CircularProgress variant="determinate" value={total} />
          )}
        </ListItemIcon>
      )}
      {!!error && (
        <ListItemIcon>
          <ErrorOutlineIcon htmlColor="red" />
        </ListItemIcon>
      )}
      <ListItemText
        primary={`Uploading ${props.file.name}...`}
        secondary={progressText}
      />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="cancel"
          onClick={() => {
            if (selectedFileXHR) selectedFileXHR.abort();
            if (props.onCanceled) props.onCanceled(props.file);
          }}
        >
          <CloseIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
